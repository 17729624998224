import { Field, useFormikContext } from "formik";
import { FormText, Label } from "reactstrap";

import ClipboardWriteButton from "components/ClipboardWriteButton";
import { FieldDescription } from "components/Forms/components/FieldDescription";
import BoolIndicator from "components/StatusIndicators/BoolIndicator";
import {
  currencyFormatter,
  dateFormatter,
  dateTimeFormatter,
} from "helpers/formatters";

import CustomField from "./CustomField";
import CreatorVisibleIndicator from "./components/CreatorVisibleIndicator";

import "./styles/index.scss";

export const EmailField = ({ email, isVerified, isEditing, ...props }) => {
  return (
    <>
      {/* // TODO: too specific, this should be custom renderer */}
      {isEditing ? (
        <Field
          className="form-control"
          name="email"
          type="email"
          placeholder="Email"
        />
      ) : (
        <>
          {email && (
            <div className="d-flex align-items-center gap-1">
              <a href={`mailto:${email}`}>{email}</a>
              <BoolIndicator value={isVerified} type="icon" />
            </div>
          )}
        </>
      )}
    </>
  );
};

export const DateField = ({ value, ...props }) => {
  return <span>{dateFormatter(value)}</span>;
};

export const DateTimeField = ({ value, ...props }) => {
  return <span>{dateTimeFormatter(value)}</span>;
};

export const StringField = ({ value, ...props }) => {
  return <span>{value}</span>;
};

export const IdField = ({ value, ...props }) => {
  return <ClipboardWriteButton textToCopy={value} />;
};

export const BoolField = ({ value, truthyText, falsyText, ...props }) => {
  return (
    <BoolIndicator
      value={value}
      truthyText={truthyText}
      falsyText={falsyText}
      type="icon"
    />
  );
};

export const CurrencyField = ({ value, ...props }) => {
  return <span className="currency">{currencyFormatter(value)}</span>;
};

export const DefaultRenderer = (type) => {
  switch (type) {
    case "string":
      return StringField;
    case "id":
      return IdField;
    case "bool":
    case "switch":
      return BoolField;
    case "date":
      return DateField;
    case "dateTime":
      return DateTimeField;
    case "currency":
      return CurrencyField;
    default:
      return StringField;
  }
};

export const LabelledData = ({
  data,
  hideConditionCompareValues = {},
  disableConditionCompareValues = {},
  isEditing,
  definition,
  editingOnly = false,
  ...props
}) => {
  const Renderer = getRenderer(isEditing, definition);
  const def = mergeDefinition(isEditing, definition, editingOnly);
  const showFullDescription = def?.showFullDescription ?? isEditing; // default to show full description when editing
  const showDescriptionIcon = def?.showDescriptionIcon;
  const values = useFormikContext()?.values;
  if (!data || !def || !Renderer || def.hidden) return null;
  // TODO: make below a helper function. also used elsewhere
  const fieldKeys = def.fieldKey?.split("."); // support nested keys
  const dataValue
    = (def.value ?? data)
      ? fieldKeys?.reduce((acc, key) => acc?.[key], data)
      : ("" ?? "");
  if (
    def?.hideCondition
    && def?.hideCondition(values, hideConditionCompareValues)
  ) {
    return null;
  }
  // HACK: this is a hack to allow dynamic componentProps
  if (typeof def?.componentPropsFunc === "function") {
    def.componentProps = {
      ...(def.componentProps || {}),
      ...def.componentPropsFunc(values),
    };
  }
  return (
    <>
      <div className="data-label">
        <Label className="text-muted m-0" htmlFor={def?.fieldKey}>
          <CreatorVisibleIndicator
            isVisible={!!def?.creatorVisible}
            showIcon={isEditing && definition?.editing}
          >
            {def?.label}
          </CreatorVisibleIndicator>
          {showDescriptionIcon && def?.description && (
            <FieldDescription description={def?.description} />
          )}
        </Label>
      </div>
      <div className="data-value">
        <Renderer
          value={def.displayValue ?? def.value ?? dataValue}
          fieldKey={def?.fieldKey}
          name={def?.fieldKey}
          type={def?.type}
          creatorVisible={def?.creatorVisible}
          hideCondition={def?.hideCondition}
          hideConditionCompareValues={hideConditionCompareValues}
          disableCondition={def?.disableCondition}
          disableConditionCompareValues={disableConditionCompareValues}
          fieldInputProps={def?.fieldInputProps}
          // TODO: are these duplicates? choose one?
          {...def?.componentProps}
          {...def?.cellRendererParams}
        />
        {def?.description && showFullDescription && (
          <div className="mb-3">
            <FormText>{def.description}</FormText>
          </div>
        )}
      </div>
    </>
  );
};

function getRenderer(isEditing, definition) {
  if (isEditing && definition?.editing) {
    if (definition?.type === "custom") {
      // * Temp hack for new custom fields - instead of rendering the passed CustomRenderer directly, CustomField will render it and hook into the formik context, add error message, etc
      return CustomField;
    }
    return definition?.editing?.CustomRenderer ?? CustomField;
  } else {
    return definition?.CustomRenderer ?? DefaultRenderer(definition?.type);
  }
}

function mergeDefinition(isEditing, definition, editingOnly) {
  // if editing, override read definitions
  let { editing, ...def } = definition;
  if (isEditing) {
    if (editingOnly && !editing) return null;
    def = { ...def, ...editing, editing: true };
  }
  return def;
}

export default LabelledData;
