import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./index.scss";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { Button, Col, Row } from "reactstrap";
import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/20/solid";

import { GridLoadingOverlay } from "components/DataGrid/components";
import { GridRowCountIndicator } from "components/DataGrid/components";
import { showToast } from "components/Toasts/helpers/showToast";
import { filterColDefs } from "helpers/filterColDefs";

import AppliedFilters from "./components/AppliedFilters";
import QuickFilter from "./components/QuickFilter";
import { columnTypes, defaultDefaultColDef } from "./constants";
import ButtonCellRenderer from "./customRenderers/ViewButtonCellRenderer";
import ViewLinkButtonCellRenderer from "./customRenderers/ViewLinkButtonCellRenderer";
import { mergeEventHandlers } from "./helpers/mergeEventHandlers";
import { useGridApis } from "./hooks/useGridApis";
import { useLoadingOverlay } from "./hooks/useLoadingOverlay";
import { usePersistGridState } from "./hooks/usePersistGridState";
import { useRowCount } from "./hooks/useRowCount";

/**
 * Local props for DataGrid component.
 *
 * @typedef {Object} DataGridProps
 * @property {string} gridId - ID/name for the grid. Grid state is stored in local storage under this name.
 * @property {Object[]} rowData - Data for the grid rows.
 * @property {Object[]} columnDefs - Definitions for the grid columns.
 * @property {Object} [defaultColDef=defaultDefaultColDef] - Default column definition.
 * @property {string} [excludeDataSources] - Comma separated string of dataSources. Column definitions with matching data sources (DataSources) will be excluded from the grid definition.
 * @property {string} [idField="_id"] - Unique identifier field for each row.
 * @property {boolean} [loading=false] - Deprecated prop for loading state of the grid. Use isLoading and isFetching instead.
 * @property {boolean} [isLoading=false] - Loading state of data initial load.
 * @property {boolean} [isFetching=false] - Fetching state of data refetch.
 * @property {boolean} [autoSizeEnabled=true] - Enable/disable auto sizing columns.
 * @property {boolean} [autoSizeIgnoreHeaders=true] - Ignore headers when auto-sizing.
 * @property {boolean} [autoHeight=false] - Auto-adjust grid height.
 * @property {string} [height="100%"] - Grid container height.
 * @property {string} [width="100%"] - Grid container width.
 * @property {boolean} [borders=false] - Show grid borders.
 * @property {boolean} [footer=true] - Show grid footer.
 * @property {boolean} [appliedFilters=false] - Show applied filters above grid.
 * @property {boolean} [quickFilter=false] - Show quick filter input above grid.
 * @property {Object} [displayedItem] - Currently displayed item in the side panel.
 * @property {Function} [changeDisplayedItem] - Function to update the displayed item.
 * @property {Function} [actionClick] - Function for action button click in side panel.
 * @property {string} [actionText] - Text for action button in side panel.
 * @property {boolean} [showLinkButton]
 * @property {string} [linkButtonTo] // Where the link button should go. Defaults to `./:_id`. Same rules as React Router NavLink `to`. Can be template string replacing keys of the rowData object.
 * @property {boolean} [linkButtonReplace] // Whether the link button should replace the current entry in the history stack. Defaults to `false`.
 * @property {React.ReactElement} [HeaderActionElem] - JSX element for additional header actions. Such as a create button.
 * @property {any} [props] - Extra properties that will be passed to AgGridReact.
 * @property {Function} [refetch] - Function to refetch data
 * @returns {JSX.Element} JSX Element
 */

/**
 * @param {DataGridProps & Record<string, any>} props
 */
const DataGrid = ({
  gridId,
  rowData,
  columnDefs,
  defaultColDef = defaultDefaultColDef,
  idField = "_id",
  loading = false,
  isLoading = false,
  isFetching = false,
  autoSizeEnabled = true,
  autoSizeIgnoreHeaders = false,
  autoHeight = false,
  height = autoHeight ? "" : "100%",
  width = "100%",
  borders = false,
  footer = true,
  appliedFilters = true,
  quickFilter = true,
  displayedItem = null,
  changeDisplayedItem = null,
  actionClick = null,
  actionText = null,
  showLinkButton = false,
  linkButtonTo = "",
  linkButtonReplace = false,
  HeaderActionElem = null,
  excludeDataSources = "",
  refetch,
  ...props
}) => {
  const [quickFilterQuery, setQuickFilterQuery] = useState("");
  const gridEvents = [];

  const { gridApi, ...apiEvents } = useGridApis();

  if (apiEvents) gridEvents.push(apiEvents);

  // show/hide loading overlay
  useLoadingOverlay({ gridApi, isLoading: isLoading || loading });

  // set row data when it changes
  useEffect(() => {
    if (!gridApi) return;
    gridApi.setRowData(rowData);
  }, [gridApi, rowData]);
  // also set row data when ready I guess. i took the below event handler out and it broke grids that were using existing data from redux store on subsequent renders
  const onGridReadyData = (params) => {
    if (!params?.api) return;
    params.api.setRowData(rowData);
  };
  gridEvents.push({ onGridReady: onGridReadyData });

  // set up getRowId function
  const getRowId = useCallback(
    function(params) {
      return params?.data[idField ?? "_id"];
    },
    [idField],
  );

  // set up column definitions for grids with action buttons
  const computedColumnDefs = useMemo(() => {
    let definitions = columnDefs;
    if (excludeDataSources) {
      const excludeArray = excludeDataSources.split(",");
      definitions = filterColDefs({
        columnDefs,
        excludeDataSources: excludeArray,
      });
    }

    if (showLinkButton) {
      const buttonCellDef = {
        headerName: "",
        field: "viewButton",
        cellRenderer: memo(ViewLinkButtonCellRenderer),
        cellRendererParams: { linkButtonTo, linkButtonReplace },
        cellClass: "d-flex justify-content-center cell-button",
        minWidth: 90,
        maxWidth: 100,
        pinned: "right",
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressMovable: true,
        lockPinned: true,
        resizable: false,
        sortable: false,
        filter: false,
      };
      definitions = [...definitions, buttonCellDef];
    } else if (changeDisplayedItem || actionClick || actionText) {
      const buttonCellDef = {
        headerName: "",
        field: "actionButton",
        cellRenderer: memo(ButtonCellRenderer),
        cellRendererParams: {
          setDisplayed: changeDisplayedItem,
          // TODO: settle discrepancy between displayed_id and displayedItem
          displayed_id: displayedItem?._id,
          actionClick,
          actionText,
        },
        cellClass: "d-flex justify-content-center cell-button",
        minWidth: 90,
        maxWidth: 100,
        pinned: "right",
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressMovable: true,
        lockPinned: true,
        resizable: false,
        sortable: false,
        filter: false,
      };
      definitions = [...definitions, buttonCellDef];
    }

    // add tooltips for headers
    definitions = definitions.map((def) => {
      if (def.headerName && !def.headerTooltip) {
        def.headerTooltip = def.headerName;
        if (def.children?.length) {
          def.children = def.children.map((child) => {
            if (child.headerName && !child.headerTooltip) {
              child.headerTooltip = `${def.headerName} ${child.headerName}`;
            }
            return child;
          });
        }
      }
      return def;
    });
    return definitions;
  }, [
    columnDefs,
    excludeDataSources,
    showLinkButton,
    actionText,
    linkButtonTo,
    linkButtonReplace,
    changeDisplayedItem,
    actionClick,
    displayedItem?._id,
  ]);

  // set up grid style prop
  const styleOptions = useMemo(
    () => ({
      height,
      width,
    }),
    [height, width],
  );

  // set up grid event handlers for persisting state
  const {
    resetGridColumns,
    resetGridFilters,
    expandGridColumns,
    fitGridColumns,
    manuallyResizedColumns,
    ...persistGridEventHandlers
  } = usePersistGridState({
    gridApi,
    gridId,
    autoSizeEnabled,
    autoSizeIgnoreHeaders,
  });
  const resetAllFilters = useCallback(() => {
    resetGridFilters();
    setQuickFilterQuery("");
  }, [resetGridFilters]);
  if (persistGridEventHandlers) gridEvents.push(persistGridEventHandlers);

  const { filteredCount, totalCount, ...rowCountEvents } = useRowCount(gridApi);
  if (rowCountEvents) gridEvents.push(rowCountEvents);

  // combine any overlapping grid event handlers
  const mergedEventHandlers = mergeEventHandlers(gridEvents);

  /**
   * Handles the download of the CSV file for the data grid.
   * @returns {void}
   */
  const handleDownloadCSV = useCallback(() => {
    if (!gridApi) return;

    const currentDate = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");
    const currentTime = new Date()
      .toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(/:/g, "");
    const fileName = `${currentDate}_${currentTime}_${gridId}_cpadmin_export.csv`; // Example filename: 20220101_123456_campaigns_cpadmin_export.csv

    showToast({
      type: "info",
      message: "Exporting grid data with applied filters to CSV.",
    });
    gridApi.exportDataAsCsv({
      fileName,
      allColumns: true,
    });
  }, [gridApi, gridId]);

  const handleRefetchClick = useCallback(() => {
    if (!refetch) return;
    refetch();
  }, [refetch]);

  const handleExpandColumns = useCallback(() => {
    if (!expandGridColumns) return;
    expandGridColumns(manuallyResizedColumns);
  }, [expandGridColumns, manuallyResizedColumns]);
  const handleFitColumns = useCallback(() => {
    if (!fitGridColumns) return;
    fitGridColumns(manuallyResizedColumns);
  }, [fitGridColumns, manuallyResizedColumns]);

  return (
    <div className="data-grid-container">
      {(appliedFilters || quickFilter || HeaderActionElem) && (
        <div className="data-grid-header">
          {(appliedFilters || quickFilter) && (
            <div className="data-grid-filters">
              <Row className="g-0">
                {quickFilter && (
                  <Col xs="12" md="6" lg="4" xl="3">
                    {/* //TODO: quick filter */}
                    <QuickFilter
                      onChange={setQuickFilterQuery}
                      value={quickFilterQuery}
                    />
                  </Col>
                )}
                {appliedFilters && (
                  <Col>
                    <AppliedFilters gridApi={gridApi} />
                  </Col>
                )}
              </Row>
            </div>
          )}
          {HeaderActionElem && (
            <div className="data-grid-action">{HeaderActionElem}</div>
          )}
        </div>
      )}
      <div
        className={`data-grid ${borders ? "border" : ""}`}
        style={styleOptions}
      >
        <AgGridReact
          className="data-grid__body ag-theme-alpine"
          domLayout={autoHeight ? "autoHeight" : "normal"}
          getRowId={getRowId}
          columnTypes={columnTypes}
          columnDefs={computedColumnDefs}
          defaultColDef={defaultColDef}
          loadingOverlayComponent={memo(GridLoadingOverlay)}
          rowSelection="multiple"
          rowBuffer={20}
          valueCache={true}
          suppressColumnVirtualisation={true}
          overlayNoRowsTemplate="No data to display"
          tooltipShowDelay={800}
          quickFilterText={quickFilterQuery}
          cacheQuickFilter={true}
          // enableCellTextSelection={true}
          ensureDomOrder={true}
          maintainColumnOrder={true} // maintain column order when colDefs are updated
          {...mergedEventHandlers}
          {...props}
        />
        {footer && (
          <div className="data-grid__footer ag-theme-alpine">
            <div className="data-grid__footer__data">
              <div className="data-grid__footer__data__row-count">
                <GridRowCountIndicator
                  isLoading={isLoading || loading}
                  isFetching={isFetching}
                  filteredCount={filteredCount}
                />
              </div>
              <div className="data-grid__footer__data__actions">
                {!!refetch && (
                  <Button
                    size="sm"
                    color="secondary"
                    className="btn-soft d-inline-flex align-items-center"
                    disabled={isFetching || isLoading}
                    onClick={handleRefetchClick}
                  >
                    <ArrowPathIcon
                      className="me-1"
                      height={16}
                      width={16}
                      strokeWidth={2.4}
                    />
                    Refresh Data
                  </Button>
                )}
                <Button
                  size="sm"
                  color="secondary"
                  className="btn-soft d-inline-flex align-items-center"
                  onClick={handleDownloadCSV}
                >
                  <ArrowDownTrayIcon
                    className="me-1"
                    height={16}
                    width={16}
                    strokeWidth={2.4}
                  />
                  Download CSV
                </Button>
              </div>
            </div>
            <div className="data-grid__footer__actions">
              <Button
                size="sm"
                color="secondary"
                className="btn-soft"
                onClick={handleExpandColumns}
              >
                Expand Columns
              </Button>
              <Button
                size="sm"
                color="secondary"
                className="btn-soft"
                onClick={handleFitColumns}
              >
                Fit Columns
              </Button>
              <Button
                size="sm"
                color="secondary"
                className="btn-soft"
                onClick={resetGridColumns}
                title="Reset column order, size, and visibility to default."
              >
                Reset Columns
              </Button>
              <Button
                size="sm"
                color="secondary"
                className="btn-soft"
                onClick={resetAllFilters}
                title="Reset applied filters and sorts to default."
              >
                Reset Filters
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataGrid;
