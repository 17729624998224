import { useMemo } from "react";

import clsx from "clsx";

import { AgreementsGrid } from "features/agreements2/components/AgreementsGrid";

export const CreatorAgreements = ({ creator_id, className, ...props }) => {
  const classes = clsx("creator-agreements", className);
  const filterParams = useMemo(() => {
    return {
      user_id: creator_id,
    };
  }, [creator_id]);
  return (
    <AgreementsGrid
      filterParams={filterParams}
      className={classes}
      gridId="creator-agreements"
      excludeDataSources={"creator"}
      create={true}
      creatorId={creator_id}
    />
  );
};
