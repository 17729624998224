export const API_URL = import.meta.env.VITE_API_URL;
const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

/**
 * Creates an action object with the given action type and data payload.
 * @param {string} actionType - The type of the action to be created.
 * @param {any} data - The data payload to be included in the action object.
 * @returns {{type: string, payload: any}} - The action object with the given action type and data payload.
 */
export function actionCreator(actionType, data) {
  return {
    type: actionType,
    payload: data,
  };
}

/**
 * Returns an object with string values for the given base action type.
 * @param {string} base - The base action type.
 * @returns {Object<string, string>} An object with string values for the given base action type.
 */
export function createRequestActionTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

/**
 * Construct headers for private requests.
 * @param {string} [contentType="application/json"] - The content type of the request.
 * @returns {Object} - An object containing headers for private requests.
 */
export const privateHeaders = (contentType = "application/json") => {
  const headers = {};
  headers["Content-Type"] = contentType;
  return headers;
};
