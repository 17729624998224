import { Outlet } from "react-router-dom";

import { AgreementsGrid } from "features/agreements2/components/AgreementsGrid";

export function AgreementsListPage() {
  return (
    <section className="agreements-list-page main-page">
      <div className="main-page__content">
        <div className="main-page__header">
          <h1>Agreements List</h1>
        </div>
        <div className="main-page__body">
          <AgreementsGrid create={false} />
        </div>
      </div>
      <Outlet />
    </section>
  );
}
