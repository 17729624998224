import { Badge } from "reactstrap";

import { agreementsStatusDict } from "features/agreements2/constants/agreementsDict";

export const AgreementStatusBadge = ({ value, ...props }) => {
  const statusConfig = agreementsStatusDict[value];
  if (!statusConfig) return null;
  const badgeColorProps = statusConfig.color
    ? { color: statusConfig.color }
    : {};
  return (
    <>
      {value && (
        <Badge {...badgeColorProps} pill>
          {statusConfig?.label}
        </Badge>
      )}
    </>
  );
};
