import { memo } from "react";

import { Badge } from "reactstrap";

import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";
import { EmailField } from "components/Forms/LabelledData";
import WarningBoolIndicator from "components/StatusIndicators/WarningBoolIndicator";
import {
  AssignmentLink,
  RemoveAssignmentButton,
} from "features/assignments/components";
import { BrandLink } from "features/brands";
import { CampaignLink } from "features/campaigns";
import { CreatorLink } from "features/creators";
import { titleCaseFormatter } from "helpers/formatters";
import CampaignStatusBadge from "modules/Campaigns/components/CampaignStatusBadge";

export const assignmentDataDefs = (displayedAssignment) => {
  return {
    header: {
      type: "string",
      label: "",
      value: `${displayedAssignment?.user_id?.name} <> ${displayedAssignment?.campaign_id?.name}`,
      CustomRenderer: memo(AssignmentLink),
      componentProps: {
        _id: displayedAssignment?._id,
        data: displayedAssignment,
      },
      editing: false,
    },
    body: [
      {
        role: "section",
        fields: [
          {
            type: "id",
            label: "Assignment ID",
            fieldKey: "_id",
            editing: false,
          },
          {
            label: "Department",
            fieldKey: "department",
            type: "string",
            displayValue:
              displayedAssignment?.department === "managed"
                ? "Managed Services"
                : titleCaseFormatter(displayedAssignment?.department),
            editing: false,
          },
          {
            label: "Status",
            creatorVisible: true,
            fieldKey: "status",
            type: "string",
            editing: true,
            CustomRenderer: () => (
              <Badge pill color="secondary">
                {displayedAssignment?.status}
              </Badge>
            ),
          },
          {
            label: "Brand",
            fieldKey: "brand_id?._id",
            displayValue: displayedAssignment?.brand_id?.name,
            type: "string",
            editing: false,
            CustomRenderer: memo(BrandLink),
            componentProps: {
              _id: displayedAssignment?.brand_id?._id,
              data: displayedAssignment,
            },
          },
          {
            label: "Custom Handle",
            fieldKey: "agencyCreatorName",
            type: "string",
            editing: {
              required: false,
            },
          },
          {
            label: "Agreements Incomplete",
            fieldKey: "__agreementMetrics.requiredIncomplete",
            type: "number",
            editing: false,
          },
          {
            label: "Agreements Complete",
            fieldKey: "__agreementMetrics.requiredComplete",
            type: "number",
            editing: false,
          },
          {
            label: "Agreements Total",
            fieldKey: "__agreementMetrics.total",
            type: "number",
            editing: false,
          },
          {
            label: "Remove Assignment",
            fieldKey: "__canRemoveCreatorAssignment", // not a real key, just a unique placeholder
            type: "bool",
            hidden: true,
            editing: {
              hidden: false,
              CustomRenderer: memo(RemoveAssignmentButton),
              componentProps: {
                assignment_id: displayedAssignment?._id,
              },
            },
          },
        ],
      },
      {
        role: "section",
        fields: [
          {
            label: "Rate",
            creatorVisible: true,
            fieldKey: "rate",
            type: "currency",
            editing: true,
          },
          {
            label: "Allocated",
            fieldKey: "__allocatedBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Unallocated",
            fieldKey: "__unallocatedBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Hold",
            creatorVisible: true,
            fieldKey: "holdBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Withdrawable",
            creatorVisible: true,
            fieldKey: "availableBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Cashed Out",
            creatorVisible: true,
            fieldKey: "paidBalance",
            type: "currency",
            editing: false,
          },
          {
            label: "Expected Pay Date",
            creatorVisible: true,
            fieldKey: "nextPayoutDate",
            type: "date",
            editing: true,
          },
        ],
      },
      {
        role: "section",
        label: "Campaign",
        fields: [
          {
            label: "Name",
            fieldKey: "campaign_id?._id",
            type: "string",
            editing: false,
            CustomRenderer: memo(CampaignLink),
            componentProps: {
              _id: displayedAssignment?.campaign_id?._id,
              data: displayedAssignment,
            },
          },
          {
            label: "Short ID",
            type: "id",
            fieldKey: "campaign_id.shortId",
            editing: false,
          },
          {
            label: "Status",
            fieldKey: "campaign_id.status",
            type: "string",
            editing: false,
            CustomRenderer: memo(CampaignStatusBadge),
          },
          {
            label: "Account Segmentation",
            fieldKey: "campaign_id.enableAccountSegmentation",
            type: "switch",
            editing: false,
          },
          // {
          //   // TODO: @backend - calculated field not returned when populating campaign_id
          //   label: "Unallocated",
          //   fieldKey: "campaign_id.unallocatedBalance",
          //   type: "currency",
          //   editing: false,
          // },
          {
            label: "Budget",
            type: "currency",
            fieldKey: "campaign_id.creatorBudget",
            editing: false,
          },
          {
            label: "Start Date",
            fieldKey: "campaign_id.startDate",
            type: "date",
            editing: false,
          },
          {
            label: "End Date",
            fieldKey: "campaign_id.endDate",
            type: "date",
            editing: false,
          },
        ],
      },
      {
        role: "section",
        label: "Creator",
        fields: [
          {
            label: "Name",
            fieldKey: "user_id?._id",
            type: "string",
            editing: false,
            CustomRenderer: memo(CreatorLink),
            componentProps: {
              _id: displayedAssignment?.user_id?._id,
              data: displayedAssignment,
            },
          },
          {
            label: "Agency/Creator",
            fieldKey: "user_id.isAgency",
            type: "boolean",
            editing: false,
            CustomRenderer: memo(WarningBoolIndicator),
            // TODO: custom select input
            componentProps: {
              value: displayedAssignment?.user_id?.isAgency,
              truthyText: "Agency",
              falsyText: "Creator",
              type: "icon",
            },
          },
          {
            label: "Email",
            creatorVisible: true,
            fieldKey: "user_id.email",
            type: "string",
            editing: false,
            CustomRenderer: EmailField,
            componentProps: {
              email: displayedAssignment?.user_id?.email,
              isVerified: displayedAssignment?.user_id?.emailVerified,
            },
          },
          {
            label: "Phone",
            creatorVisible: true,
            fieldKey: "user_id.phone",
            type: "string",
            editing: false,
          },
          {
            label: "Country",
            creatorVisible: true,
            fieldKey: "user_id.country",
            type: "string",
            editing: false,
          },
          {
            label: "Stripe Onboarding",
            creatorVisible: true,
            fieldKey: "user_id.needsStripeOnboarding",
            type: "boolean",
            CustomRenderer: memo(BoolCellRenderer),
            componentProps: {
              value: !displayedAssignment?.user_id?.needsStripeOnboarding,
              type: "icon",
              truthyText: "Complete",
              falsyText: "Not Completed",
            },
            editing: false,
          },
          {
            label: "Financial Account",
            creatorVisible: true,
            fieldKey: "user_id.needsStripeFinancialConnection",
            type: "boolean",
            cellDataType: "boolean", // this should be handled by type: "boolean" above, but boolean type is not applied when valueGetter is used
            CustomRenderer: memo(BoolCellRenderer),
            componentProps: {
              value:
                !displayedAssignment?.user_id?.needsStripeFinancialConnection,
              type: "icon",
              truthyText: "Linked",
              falsyText: "Not Linked",
            },
            editing: false,
          },
          // {
          //   label: "Last Login",
          //   fieldKey: "user_id.lastLogin",
          //   type: "date",
          //   editing: false,
          // },
        ],
      },
    ],
  };
};

// export const creatorCampaignAssignmentDataDefs = (displayedAssignment) => {
//   return {
//     header: {
//       type: "string",
//       label: "Campaign Assignment",
//       value: displayedAssignment?.campaignName,
//       editing: false,
//     },
//     body: [
//       {
//         role: "section",
//         fields: [
//           {
//             label: "Assignment ID",
//             fieldKey: "_id",
//             type: "id",
//             editing: false,
//           },
//           {
//             label: "Assignment Status",
//             creatorVisible: true,
//             fieldKey: "status",
//             type: "string",
//             editing: true,
//             CustomRenderer: () => (
//               <Badge pill color="secondary">
//                 {displayedAssignment?.status}
//               </Badge>
//             ),
//           },
//           {
//             label: "Campaign Status",
//             fieldKey: "campaign_id.status",
//             type: "string",
//             editing: false,
//             CustomRenderer: CampaignStatusBadge,
//           },
//           {
//             label: "Brand",
//             fieldKey: "brand_id?._id",
//             displayValue: displayedAssignment?.brand_id?.name,
//             type: "string",
//             editing: false,
//           },
//           {
//             label: "Start Date",
//             fieldKey: "campaign_id.startDate",
//             type: "date",
//             editing: false,
//           },
//           {
//             label: "End Date",
//             fieldKey: "campaign_id.endDate",
//             type: "date",
//             editing: false,
//           },
//           {
//             label: "",
//             fieldKey: "campaign_id._id",
//             type: "link",
//             editing: {
//               hidden: true,
//             },
//             CustomRenderer: () => (
//               <Link
//                 to={`/campaigns/${
//                   displayedAssignment?.campaign_id?._id
//                   || displayedAssignment?.campaign_id
//                 }`}
//                 className="small"
//               >
//                 View full Campaign Details...
//               </Link>
//             ),
//           },
//           {
//             label: "Remove from Campaign",
//             fieldKey: "__canRemoveCreatorAssignment", // not a real key, just a unique placeholder
//             type: "bool",
//             hidden: true,
//             editing: {
//               hidden: false,
//               CustomRenderer: memo(RemoveCampaignAssignmentButton),
//               componentProps: {
//                 assignment: displayedAssignment,
//               },
//             },
//           },
//         ],
//       },
//       {
//         role: "section",
//         label: "Balances",
//         fields: [
//           {
//             label: "Total Rate",
//             creatorVisible: true,
//             fieldKey: "rate",
//             type: "currency",
//             editing: true,
//           },
//           {
//             label: "Rate per Post",
//             creatorVisible: true,
//             fieldKey: "ratePerPost",
//             type: "currency",
//             editing: true,
//           },
//           {
//             label: "Available",
//             creatorVisible: true,
//             fieldKey: "availableBalance",
//             type: "currency",
//             editing: false,
//           },
//           {
//             label: "Hold",
//             creatorVisible: true,
//             fieldKey: "holdBalance",
//             type: "currency",
//             editing: false,
//           },
//           {
//             label: "Paid",
//             creatorVisible: true,
//             fieldKey: "paidBalance",
//             type: "currency",
//             editing: false,
//           },
//           {
//             label: "Expected Pay Date",
//             creatorVisible: true,
//             fieldKey: "nextPayoutDate",
//             type: "date",
//             editing: true,
//           },
//         ],
//       },
//     ],
//   };
// };
