import { useCallback, useEffect, useState } from "react";

import { Modal, ModalBody } from "reactstrap";

import Loader from "components/Loader";
import AgreementModalFooter from "features/agreements2/components/Agreement/AgreementModalFooter";
import AgreementModalHeader from "features/agreements2/components/Agreement/AgreementModalHeader";
import { AgreementModalProvider } from "features/agreements2/components/Agreement/context/AgreementModalProvider";
import useAgreementCreate from "features/agreements2/components/Agreement/hooks/useAgreementCreate";
import useAgreementData from "features/agreements2/components/Agreement/hooks/useAgreementData";
import useAgreementUpdate from "features/agreements2/components/Agreement/hooks/useAgreementUpdate";

import { useAgreementContext } from "./context/AgreementContext";

/**
 * Renders a modal component for displaying an agreement (or the create agreement form).
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - The open state of the modal.
 * @param {Function} props.setIsOpen - The function to set the modal open state.
 * @param {React.ReactNode} props.children - The content to be rendered inside the modal.
 * @returns {JSX.Element} The rendered modal component.
 */
const AgreementModal = ({ isOpen, setIsOpen, children }) => {
  const { agreementUIStatus } = useAgreementContext();
  const [modalSize, setModalSize] = useState(null);

  // * track loading states for create, update, and fetch
  // TODO: move this out of modal and into somewhere that makes more sense
  const { isLoading: fetchLoading } = useAgreementData();
  const { isLoading: createLoading } = useAgreementCreate();
  const { isLoading: updateLoading } = useAgreementUpdate();

  // * when UI status changes, set modal size
  useEffect(() => {
    // the boldsign iframe embeds need a wider modal size to look good
    // boldsign iframes are displayed when UIStatus is "sign" or "config"
    setModalSize(
      agreementUIStatus === "sign" || agreementUIStatus === "config"
        ? "xl"
        : null,
    );
    if (agreementUIStatus === "closed") {
      setIsOpen(false);
    }
  }, [agreementUIStatus, setIsOpen]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      size={modalSize}
      className="agreement-modal"
      unmountOnClose={true}
    >
      <AgreementModalProvider
        isAgreementModalOpen={isOpen}
        setIsAgreementModalOpen={setIsOpen}
        toggleAgreementModal={toggleModal}
      >
        {(createLoading || updateLoading || fetchLoading) && <Loader />}
        <AgreementModalHeader />
        <ModalBody>{children}</ModalBody>
        <AgreementModalFooter />
      </AgreementModalProvider>
    </Modal>
  );
};

export default AgreementModal;
