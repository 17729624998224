import { LinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/LinkCellRenderer";

/**
 * Renders a link to a campaign based on the provided grid cell value.
 *
 * @param {Object} props
 * @param {string} props.value - The ID of the campaign. Passed from ag-Grid.
 * @param {object} props.data - The row data of the assignment. Passed from ag-Grid.
 * @param {object} [props.linkTextKey] - The key used to fetch value to display in the link. Defaults to Campaign Name
 * @returns {JSX.Element} The rendered CampaignLink component.
 */
export const CampaignLinkCellRenderer = ({ value, data, linkTextKey = "" }) => {
  let text;
  let muted;

  if (!value) {
    muted = true;
  }
  if (linkTextKey) {
    const linkTextKeys = linkTextKey.replace(/\?/g, "").split(".");
    text = linkTextKeys.reduce((obj, key) => obj?.[key], data);
  } else {
    text = data?.campaign_id?.name || data?.campaignName || data?.name;
  }

  if (!text) {
    text = "";
    muted = true;
  }

  const to = muted ? null : `/campaigns/${value}`;

  return to ? (
    <LinkCellRenderer to={to} text={text} />
  ) : (
    <span className="text-muted">{text}</span>
  );
};
