import { secretbox, randomBytes, hash } from "tweetnacl";
import {
  encodeBase64,
  decodeBase64,
  encodeUTF8,
  decodeUTF8,
} from "tweetnacl-util";

/**
 * Interface for encryption key management
 */
interface IEncryptionKeyManager {
  key: Uint8Array | null;
  setKey: (key: Uint8Array) => void;
  getKey: () => Uint8Array | null;
  clearKey: () => void;
}

/**
 * Singleton service for managing encryption keys
 */
export const encryptionKeyManager: IEncryptionKeyManager = {
  key: null,
  setKey(key: Uint8Array) {
    this.key = key;
  },
  getKey() {
    return this.key;
  },
  clearKey() {
    this.key = null;
  },
};

/**
 * Generate a key from auth data
 * @param authData - Data from successful authentication to derive key from
 */
export async function generateKeyFromAuth(authData: any): Promise<Uint8Array> {
  // Create consistent auth string
  // TODO: use session token when generating key
  const keyMaterial = JSON.stringify({
    _id: authData._id,
    email: authData.email,
    created: authData.created,
    sessionToken: "some session token2",
  });

  // Convert to Uint8Array and hash
  const encoder = new TextEncoder();
  const keyData = encoder.encode(keyMaterial);

  // TweetNaCl's hash function produces 64 bytes, take first 32 for secretbox
  return hash(keyData).slice(0, 32);
}

/**
 * Encrypt data using secretbox
 * @param data - Data to encrypt
 */
export function encrypt(data: any): string {
  const key = encryptionKeyManager.getKey();
  if (!key) throw new Error("No encryption key available");

  // Generate random nonce
  const nonce = randomBytes(secretbox.nonceLength);

  // Convert data to Uint8Array
  const messageUint8 = decodeUTF8(JSON.stringify(data));

  // Encrypt
  const box = secretbox(messageUint8, nonce, key);

  // Combine nonce and encrypted data
  const fullMessage = new Uint8Array(nonce.length + box.length);
  fullMessage.set(nonce);
  fullMessage.set(box, nonce.length);

  // Convert to base64 for storage
  return encodeBase64(fullMessage);
}

/**
 * Decrypt data using secretbox
 * @param encryptedData - Base64 encoded encrypted data
 */
export function decrypt(encryptedData: string): any {
  const key = encryptionKeyManager.getKey();
  if (!key) throw new Error("No encryption key available");

  try {
    // Convert from base64
    const messageWithNonce = decodeBase64(encryptedData);

    // Extract nonce
    const nonce = messageWithNonce.slice(0, secretbox.nonceLength);
    const message = messageWithNonce.slice(secretbox.nonceLength);

    // Decrypt
    const decrypted = secretbox.open(message, nonce, key);
    if (!decrypted) throw new Error("Decryption failed");

    // Convert to string and parse JSON
    const decoded = encodeUTF8(decrypted);
    return JSON.parse(decoded);
  } catch (error) {
    console.error("Decryption failed:", error);
    throw new Error("Failed to decrypt data");
  }
}
