import { isValidDate } from "helpers/isValidDate";

// TODO: refactor these. they are copied from legacy frontend

// payments that
export const processUpcomingMilestones = (assignments) => {
  const returnArr = [];

  assignments.forEach((assignment) => {
    let totalOwed = 0;
    let oldestDue;
    let soonestUpcomingDue;

    // for every assignment
    // for every assignment's milestones
    // for milestones that have a hold balance
    // are complete AND are released for payment
    // add milestone amount to totalOwed
    // track oldest due milestone
    // for milestones (not complete OR not released for payment) AND have a valid nextPaymentDate
    // track soonestUpcomingDue date of milestone with soonest nextPaymentDat
    // if soonestUpcomingDue was not set by a milestone, use assignment nextPayoutDate as soonestUpcomingDue date
    // calculate line item potentially owed value - assignment holdBalance minus totalOwed (calced from total of all completed and released milestones)
    // filter milestones for only those with a hold balance and either are not complete or not released for payment
    // return either calculated assignment data (if no milestones) or return milestones

    assignment.milestones.forEach((mile) => {
      if (mile.holdBalance) {
        if (mile.isComplete && mile.releasePayment) {
          totalOwed += mile.holdBalance;
          const mileDate = mile.nextPaymentDate
            ? new Date(mile.nextPaymentDate)
            : new Date(mile.completedDate);
          if (
            isValidDate(mileDate) &&
            (!oldestDue || mileDate < new Date(oldestDue))
          ) {
            oldestDue = mileDate;
          }
        }

        if (
          (!mile.isComplete || !mile.releasePayment) &&
          mile.nextPaymentDate
        ) {
          const nextPaymentDate = new Date(mile.nextPaymentDate);
          if (
            isValidDate(nextPaymentDate) &&
            (!soonestUpcomingDue ||
              nextPaymentDate < new Date(soonestUpcomingDue))
          ) {
            soonestUpcomingDue = nextPaymentDate;
          }
        }
      }
    });

    const nextPayoutDate = new Date(assignment.nextPayoutDate);
    if (!soonestUpcomingDue && isValidDate(nextPayoutDate)) {
      soonestUpcomingDue = nextPayoutDate;
    }

    const milestonePotentiallyOwed = assignment.holdBalance - totalOwed;
    const milestoneTotalOwed = totalOwed;
    let name = "";
    if (assignment.user_id?.name) {
      name = assignment.user_id?.name;
    } else {
      if (assignment.user_id?.firstName) {
        name = assignment.user_id?.firstName;
      }
      if (assignment.user_id?.lastName) {
        name += `${assignment.user_id?.firstName ? " " : ""}${
          assignment.user_id?.lastName
        }`;
      }
    }

    const assignmentBalances = {
      assignmentRate: assignment.rate,
      assignmentPaidBalance: assignment.paidBalance,
      assignmentAvailableBalance: assignment.availableBalance,
      assignmentHoldBalance: assignment.holdBalance,
      //     assignmentPotentiallyOwed: item.holdBalance && item.holdBalance > totalOwed? item.holdBalance - totalOwed:0,
      assignmentPotentiallyOwed: assignment.holdBalance - totalOwed,
      assignmentOwed: totalOwed,
    };
    // eslint-disable-next-line no-unused-vars
    let hasMilestones = false;
    assignment.milestones.forEach((mile) => {
      if ((mile.isComplete && mile.releasePayment) || !mile.holdBalance) {
        return;
      }
      hasMilestones = true;
      const obj = {
        ...mile,
        ...assignmentBalances,
        creatorName: name,
        campaignName: assignment.campaignName,
        assignment_id: assignment._id,
        email: assignment.user_id?.email,
        brand_id: assignment.brand_id,
        user: assignment.user_id,
      };
      returnArr.push(obj);
    });

    if (!assignment.milestones.length) {
      returnArr.push({
        name: "No Milestone",
        ...assignment,
        ...assignmentBalances,
        creatorName: name,
        milestoneTotalOwed,
        soonestUpcomingDue,
        oldestDue,
        milestonePotentiallyOwed,
        user: assignment.user_id,
        email: assignment.user_id?.email,
      });
    }
  });

  return returnArr;
};

export const processDueMilestones = (assignments) => {
  const returnArr = [];

  assignments.forEach((assignment) => {
    let totalOwed = 0;
    let oldestDue;

    // for each assignment
    // for each assignment milestone
    // calc totalOwed of all milestones where holdBalance > 0, is complete, and is released
    // calc oldest nextPaymentDate of milestones
    // filter assignment milestones, only return milestones where milestone holdBalance > 0, is complete, and is released
    assignment.milestones.forEach((mile) => {
      if (mile.isComplete && mile.releasePayment && mile.holdBalance) {
        totalOwed += mile.holdBalance;

        if (mile.nextPaymentDate) {
          const nextPaymentDate = new Date(mile.nextPaymentDate);
          if (
            isValidDate(nextPaymentDate) &&
            (!oldestDue || nextPaymentDate < new Date(oldestDue))
          ) {
            oldestDue = nextPaymentDate;
          }
        }
      }
    });

    const assignmentBalances = {
      assignmentRate: assignment.rate,
      assignmentPaidBalance: assignment.paidBalance,
      assignmentAvailableBalance: assignment.availableBalance,
      assignmentHoldBalance: assignment.holdBalance,
      //     assignmentPotentiallyOwed: item.holdBalance && item.holdBalance > totalOwed? item.holdBalance - totalOwed:0,
      assignmentPotentiallyOwed: assignment.holdBalance - totalOwed,
      assignmentOwed: totalOwed,
    };
    let name = "";
    if (assignment.user_id?.name) {
      name = assignment.user_id?.name;
    } else {
      if (assignment.user_id?.firstName) {
        name = assignment.user_id?.firstName;
      }
      if (assignment.user_id?.lastName) {
        name += `${assignment.user_id?.firstName ? " " : ""}${
          assignment.user_id?.lastName
        }`;
      }
    }

    assignment.milestones.forEach((mile) => {
      if (!mile.isComplete || !mile.releasePayment || !mile.holdBalance) {
        return;
      }

      const obj = {
        ...mile,
        ...assignmentBalances,
        creatorName: name,
        campaignName: assignment.campaignName,
        assignment_id: assignment._id,
        email: assignment.user_id?.email,
        brand_id: assignment.brand_id,
        user: assignment.user_id,
      };
      returnArr.push(obj);
    });
  });

  return returnArr;
};

export const processUpcomingAssignments = (assignments) => {
  const returnArr = [];

  assignments.forEach((item) => {
    let totalOwed = 0;
    let oldestDue;
    let soonestUpcomingDue;
    let milestonePotentiallyOwed = 0;

    item.milestones.forEach((mile) => {
      if (mile.holdBalance) {
        if (mile.isComplete && mile.releasePayment) {
          totalOwed += mile.holdBalance;
          if (mile.nextPaymentDate) {
            const mileDate = isValidDate(new Date(mile.nextPaymentDate))
              ? new Date(mile.nextPaymentDate)
              : new Date(mile.completedDate);
            if (!oldestDue || mileDate < new Date(oldestDue)) {
              oldestDue = mileDate;
            }
          }
        }
        if (!mile.isComplete || !mile.releasePayment) {
          milestonePotentiallyOwed += mile.holdBalance;
          if (mile.nextPaymentDate) {
            const nextPaymentDate = new Date(mile.nextPaymentDate);
            if (
              isValidDate(nextPaymentDate) &&
              (!soonestUpcomingDue ||
                nextPaymentDate < new Date(soonestUpcomingDue))
            ) {
              soonestUpcomingDue = nextPaymentDate;
            }
          }
        }
      }
    });

    if (item.nextPayoutDate) {
      const nextPayoutDate = new Date(item.nextPayoutDate);
      if (!soonestUpcomingDue && isValidDate(nextPayoutDate)) {
        soonestUpcomingDue = nextPayoutDate;
      }
    }

    //   let milestonePotentiallyOwed = item.holdBalance - totalOwed;
    const finalPotentiallyOwed =
      item.holdBalance - totalOwed > milestonePotentiallyOwed
        ? item.holdBalance - totalOwed
        : milestonePotentiallyOwed;
    const milestoneTotalOwed = totalOwed;
    let name = "";
    if (item.user_id?.name) {
      name = item.user_id?.name;
    } else {
      if (item.user_id?.firstName) {
        name = item.user_id?.firstName;
      }
      if (item.user_id?.lastName) {
        name += `${item.user_id?.firstName ? " " : ""}${
          item.user_id?.lastName
        }`;
      }
    }

    item.milestones = item.milestones
      .map((mile) => {
        if ((mile.isComplete && mile.releasePayment) || !mile.holdBalance) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        const obj = {
          ...mile,
          campaignName: item.campaignName,
          assignment_id: item._id,
          user: item.user_id,
        };
        return obj;
      })
      .filter((mile) => !!mile);
    returnArr.push({
      ...item,
      name,
      finalPotentiallyOwed,
      milestoneTotalOwed,
      soonestUpcomingDue,
      oldestDue,
      milestonePotentiallyOwed,
      user: item.user_id,
      email: item.user_id?.email,
    });
  });

  return returnArr;
};

export const processDueAssignments = (assignments) => {
  const returnArr = [];

  assignments.forEach((item) => {
    let totalOwed = 0;
    let oldestDue;
    let soonestUpcomingDue;

    item.milestones.forEach((mile) => {
      if (mile.holdBalance) {
        if (mile.isComplete && mile.releasePayment) {
          totalOwed += mile.holdBalance;
          if (mile.nextPaymentDate) {
            const mileDate = isValidDate(new Date(mile.nextPaymentDate))
              ? new Date(mile.nextPaymentDate)
              : new Date(mile.completedDate);
            if (!oldestDue || mileDate < new Date(oldestDue)) {
              oldestDue = mileDate;
            }
          }
        }
        if (
          (!mile.isComplete || !mile.releasePayment) &&
          mile.nextPaymentDate
        ) {
          const nextPaymentDate = new Date(mile.nextPaymentDate);
          if (
            isValidDate(nextPaymentDate) &&
            (!soonestUpcomingDue ||
              nextPaymentDate < new Date(soonestUpcomingDue))
          ) {
            soonestUpcomingDue = nextPaymentDate;
          }
        }
      }
    });

    if (item.nextPayoutDate) {
      const nextPayoutDate = new Date(item.nextPayoutDate);
      if (!soonestUpcomingDue && isValidDate(nextPayoutDate)) {
        soonestUpcomingDue = nextPayoutDate;
      }
    }

    const milestonePotentiallyOwed = item.holdBalance - totalOwed;
    const milestoneTotalOwed = totalOwed;
    let name = "";
    if (item.user_id?.name) {
      name = item.user_id?.name;
    } else {
      if (item.user_id?.firstName) {
        name = item.user_id?.firstName;
      }
      if (item.user_id?.lastName) {
        name += `${item.user_id?.firstName ? " " : ""}${
          item.user_id?.lastName
        }`;
      }
    }

    item.milestones = item.milestones
      .map((mile) => {
        if (!mile.isComplete || !mile.releasePayment || !mile.holdBalance) {
          return false;
        }

        const obj = {
          ...mile,
          campaignName: item.campaignName,
          assignment_id: item._id,
          user: item.user_id,
        };

        return obj;
      })
      .filter((mile) => !!mile);

    returnArr.push({
      ...item,
      name,
      milestoneTotalOwed,
      soonestUpcomingDue,
      oldestDue,
      milestonePotentiallyOwed,
      user: item.user_id,
      email: item.user_id?.email,
    });
  });

  return returnArr;
};
