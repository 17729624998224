import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { objectToFormData } from "helpers";
import { REHYDRATE } from "redux-persist";

import { providesListTags } from "app/providesListTags";

export const apiSlice = createApi({
  reducerPath: "api",
  extractRehydrationInfo(action, { reducerPath }) {
    if (action?.type === REHYDRATE && action?.payload) {
      console.debug("extractRehydrationInfo", {
        action,
        reducerPath,
        payload: action?.payload,
      });
      return action?.payload?.[reducerPath];
    }
  },
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/api/v1`,
    /*
     * If headers are set here, it overrides headers set in individual endpoints.
     * Ex: Couldn't send multipart form data until I commented out prepareHeaders
     * https://github.com/reduxjs/redux-toolkit/issues/2107
     */
    // prepareHeaders(headers) {
    //   const pHeaders = privateHeaders();
    //   Object.entries(pHeaders).forEach(([key, value]) => {
    //     const header = headers.get(key);
    //     headers.set(key, value);
    //   });
    //   return headers;
    // },
    credentials: "include",
  }),
  keepUnusedDataFor: 60 * 60 * 0.5,
  refetchOnMountOrArgChange: 60 * 1,
  // TODO: @segmentation do invalidate tags for Creators, Campaigns, Transactions
  tagTypes: [
    "Admins",
    "Brands",
    "BrandUsers",
    "Campaigns",
    "Creators",
    "Assignments",
    "Milestones", // * technically this is a sub-resource of assignments
    "Transfers",
    "Invoices",
    "Transactions",
    "Deliverables",
    "Agreements",
    "Balances",
    "Topups",
  ],
  endpoints: (build) => ({
    // * Balances
    getStripeBalances: build.query({
      query: () => ({
        url: "stripe/balance",
      }),
      providesTags: [{ type: "Balances", id: "LIST" }],
    }),

    // * use existing getTopups query instead. Should also rename it.
    // getStripeBalancesAndTopups: build.query({
    //   query: () => ({
    //     url: "stripe/balanceAndTopups",
    //   }),
    //   providesTags: [{ type: "Balances", id: "LIST" }],
    // }),
    getCampaignBalances: build.query({
      query: () => ({
        url: "campaign/calculateTotals",
      }),
      providesTags: [{ type: "Balances", id: "LIST" }],
    }),
    getCreatorBalances: build.query({
      query: () => ({
        url: "assignment/calculateTotals",
      }),
      providesTags: [{ type: "Balances", id: "LIST" }],
    }),
    // * Topups and Balances (new combined API)
    getTopups: build.query({
      query: () => ({
        url: "stripe/balanceAndTopups",
      }),
      providesTags: [
        { type: "Topups", id: "LIST" },
        { type: "Balances", id: "LIST" },
      ],
    }),
    createCampaignTopup: build.mutation({
      query: (topup) => ({
        url: `stripe/topup/campaign/${topup.campaign_id}`,
        method: "POST",
        body: topup,
      }),
      invalidatesTags: [
        { type: "Balances", id: "LIST" },
        { type: "Topups", id: "LIST" },
        { type: "Campaigns", id: "LIST" },
        { type: "Brands", id: "LIST" },
        { type: "Assignments", id: "LIST" },
      ],
    }),

    //     | Parameter Name         | Type     | Default | Required | Description       |
    // | :--------------------- | :------- | :------ | :------- | :---------------- |
    // | `description`          | `String` |         | `true`   |                   |
    // | `amount`               | `Number` |         | `true`   |                   |
    createPlatformTopup: build.mutation({
      query: (topup) => ({
        url: "stripe/topup/platform",
        method: "POST",
        body: topup,
      }),
      invalidatesTags: [
        { type: "Balances", id: "LIST" },
        { type: "Topups", id: "LIST" },
        { type: "Campaigns", id: "LIST" },
        { type: "Brands", id: "LIST" },
        { type: "Assignments", id: "LIST" },
      ],
    }),
    // * Admins
    getAdmins: build.query({
      query: () => ({
        url: "admins",
        params: {
          skip: 0,
          limit: 0,
        },
      }),
      providesTags: (result) => providesListTags(result?.results, "Admins"),
    }),
    // * Creators

    /**
     * Fetches creators based on the provided parameters.
     *
     * @param {Object} params
     * @param {number} [params.skip=0] - Number of records to skip.
     * @param {number} [params.limit=0] - Maximum number of records to return.
     * @param {string} [params.sortKey] - Key to sort the records by.
     * @param {number} [params.sortValue] - Value to sort the records by.
     * @param {string} [params.createdFrom] - Filter records created from this date.
     * @param {string} [params.createdTo] - Filter records created to this date.
     * @param {string} [params.status] - Comma-separated string. Enum: `active`,`completed`,`paused`, `waiting`.
     * @param {string} [params.calculate="availableBalance"] - Space-separated string. Can calculate "canDeleteUser", "earningsHistory", "holdBalance", "earnings", "availableBalance", "pendingAvailableBalance", "contentCounts", "assignmentCount", "agreementMetrics".
     */
    getCreators: build.query({
      query: ({
        skip = 0,
        limit = 0,
        calculate = "availableBalance agreementMetrics",
        ...params
      }) => ({
        url: "users",
        params: {
          skip,
          limit,
          calculate,
          ...params,
        },
      }),
      providesTags: (result) => providesListTags(result?.results, "Creators"),
      keepUnusedDataFor: 60 * 60 * 3,
    }),
    getCreator: build.query({
      query: ({
        _id,
        calculate = "availableBalance canDeleteUser agreementMetrics",
      }) => ({
        url: "user",
        params: {
          _id,
          calculate,
        },
      }),
      providesTags: (_result, _error, id) => [{ type: "Creators", id }],
    }),
    updateCreator: build.mutation({
      query: (creator) => ({
        url: "user",
        method: "PUT",
        body: creator,
      }),
      invalidatesTags: (_result, _error, creator) => {
        return [{ type: "Creators", id: creator?._id }];
      },
    }),
    inviteCreator: build.mutation({
      query: (creator) => ({
        url: "user/invitation",
        method: "POST",
        body: creator,
      }),
      invalidatesTags: (result, _error, _creator) => {
        return [
          { type: "Creators", id: result?._id },
          { type: "Campaigns", id: result?.campaign_id },
          { type: "Brands", id: result?.brand_id },
          { type: "Balances", id: "LIST" },
        ];
      },
    }),
    //* Campaigns
    /**
     * Fetches campaigns based on the provided parameters.
     *
     * @param {Object} params
     * @param {number} [params.skip=0] - Number of records to skip.
     * @param {number} [params.limit=0] - Maximum number of records to return.
     * @param {string} [params.sortKey] - Key to sort the records by.
     * @param {number} [params.sortValue] - Value to sort the records by.
     * @param {string} [params.createdFrom] - Filter records created from this date.
     * @param {string} [params.createdTo] - Filter records created to this date.
     * @param {string} [params.status] - Comma-separated string. Enum: `active`,`completed`,`paused`, `waiting`.
     * @param {string} [params.brand_id] - Filter records by brand ID.
     * @param {boolean} [params.allowContentApproval] - Filter records by content approval status.
     * @param {boolean} [params.allowClientAccess] - Filter records by client access status.
     * @param {string} [params.populate="brand_id"] - Fields to populate in the response.
     * @param {string} [params.calculate="assignmentBalances"] - Space-separated string. Can calculate `assignmentBalances`, `campaignStatus`, `agreementMetrics`.
     */
    getCampaigns: build.query({
      query: ({
        skip = 0,
        limit = 0,
        calculate = "assignmentBalances campaignStatus agreementMetrics",
        populate = "brand_id",
        ...params
      }) => ({
        url: "campaigns",
        params: {
          skip,
          limit,
          calculate,
          populate,
          ...params,
        },
      }),
      keepUnusedDataFor: 60 * 60 * 3,
      providesTags: (result) => providesListTags(result?.results, "Campaigns"),
    }),
    getCampaign: build.query({
      query: ({
        _id,
        calculate = "assignmentBalances campaignStatus agreementMetrics",
        populate = "brand_id",
      }) => ({
        url: "campaign",
        params: {
          _id,
          calculate,
          populate,
        },
      }),
      providesTags: (_result, _error, params) => [
        { type: "Campaigns", id: params?._id },
        { type: "Campaigns", id: "LIST" },
      ],
    }),
    /**
     * Creates a campaign
     *
     * @param {Object} campaign - The campaign object to be created.
     * @param {string} campaign.name
     * @param {string} campaign.brand_id
     * @param {string} [campaign.status] // Enum: `active`,`completed`,`paused`, `waiting`
     * @param {string} [campaign.shortId]
     * @param {string} [campaign.department] // Enum: 'talent', 'managed'
     * @param {string} [campaign.startDate]
     * @param {string} [campaign.endDate]
     * @param {number} [campaign.sowAmount]
     * @param {string} [campaign.creatorBudget]
     * @param {boolean} [campaign.allowContentApproval]
     * @param {boolean} [campaign.allowClientAccess]
     * @param {boolean} [campaign.enableAccountSegmentation]
     * @param {number} [campaign.defaultRate]
     * @param {string} [campaign.overviewFile]
     * @param {boolean} [campaign.populate]
     * @param {boolean} [campaign.calculate] // can calculate "assignmentBalances", "campaignStatus", "agreementMetrics"
     */
    createCampaign: build.mutation({
      query: (campaign) => ({
        url: "campaign",
        method: "POST",
        body: campaign,
      }),
      invalidatesTags: (_result, _error) => [
        { type: "Campaigns", id: "LIST" },
        { type: "Brands", id: "LIST" },
        { type: "Balances", id: "LIST" },
      ],
    }),
    updateCampaign: build.mutation({
      query: (campaign) => ({
        url: "campaign",
        method: "PUT",
        body: campaign,
      }),
      // @ts-ignore
      invalidatesTags: (result, _error, _campaign) => [
        { type: "Campaigns", id: result?.result?._id },
        { type: "Brands", id: result?.result?.brand_id },
        { type: "Assignments", id: "LIST" },
        { type: "Milestones", id: "LIST" },
        { type: "Balances", id: "LIST" },
      ],
    }),
    //* Brands
    getBrands: build.query({
      query: () => ({
        url: "brands",
        params: {
          skip: 0,
          limit: 0,
          calculate: "campaignBalances",
        },
      }),
      keepUnusedDataFor: 60 * 60 * 3,
      providesTags: (result) => providesListTags(result?.results, "Brands"),
    }),
    getBrand: build.query({
      query: ({ _id, calculate = "campaignBalances" }) => ({
        url: "brand",
        params: {
          _id,
          calculate,
        },
      }),
      providesTags: (_result, _error, id) => [
        { type: "Brands", id },
        { type: "Brands", id: "LIST" },
      ],
    }),
    createBrand: build.mutation({
      query: (brand) => ({
        url: "brand",
        method: "POST",
        body: brand,
      }),
      invalidatesTags: (_result, _error) => [{ type: "Brands", id: "LIST" }],
    }),
    updateBrand: build.mutation({
      query: (brand) => ({
        url: `brand/${brand?._id}`,
        method: "PUT",
        body: brand,
      }),
      invalidatesTags: (_result, _error, brand) => [
        { type: "Brands", id: brand?._id },
      ],
    }),
    //* Brand Users
    /**
     * Fetches brand/client users based on the provided parameters.
     *
     * @param {Object} params
     * @param {string} [params.brand_id] - Filter records by brand ID.
     * @param {number} [params.skip=0] - Number of records to skip.
     * @param {number} [params.limit=0] - Maximum number of records to return.
     * @param {string} [params.sortKey] - Key to sort the records by.
     * @param {number} [params.sortValue] - Value to sort the records by.
     * @param {string} [params.createdFrom] - Filter records created from this date.
     * @param {string} [params.createdTo] - Filter records created to this date.
     */
    getBrandUsers: build.query({
      query: ({ skip = 0, limit = 0, ...params }) => ({
        url: "brandUsers",
        params: {
          skip,
          limit,
          ...params,
        },
      }),
      providesTags: (result) => providesListTags(result?.results, "BrandUsers"),
    }),
    getBrandUser: build.query({
      query: (id) => ({
        url: `brandUser/${id}`,
      }),
      providesTags: (_result, _error, id) => [
        { type: "BrandUsers", id },
        { type: "BrandUsers", id: "LIST" },
      ],
    }),
    /**
     * Creates a brand/client user.
     *
     * @param {Object} params
     * @param {string} [params.brand_id] - Filter records by brand ID.
     * @param {number} [params.skip=0] - Number of records to skip.
     * @param {number} [params.limit=0] - Maximum number of records to return.
     * @param {string} [params.sortKey] - Key to sort the records by.
     * @param {number} [params.sortValue] - Value to sort the records by.
     * @param {string} [params.createdFrom] - Filter records created from this date.
     * @param {string} [params.createdTo] - Filter records created to this date.
     */
    inviteBrandUser: build.mutation({
      query: ({ email, name, brand_id, enableNotifications }) => ({
        url: "brandUser/invitation",
        method: "POST",
        body: {
          email,
          name,
          brand_id,
          enableNotifications,
        },
      }),
      invalidatesTags: (result, _error) => [
        { type: "BrandUsers", id: "LIST" },
        { type: "Brands", id: result?.result?.brand_id },
      ],
    }),
    updateBrandUser: build.mutation({
      query: ({ _id, enableNotifications, disabled }) => ({
        url: "brandUser",
        method: "PUT",
        body: { _id, enableNotifications, disabled },
      }),
      invalidatesTags: (result, _error, { _id }) => [
        { type: "BrandUsers", id: _id },
        { type: "BrandUsers", id: "LIST" },
        { type: "Brands", id: result?.result?.brand_id },
      ],
    }),
    //* Assignments and Milestones
    /**
     * Fetches assignments based on the provided parameters.
     *
     * @param {Object} params
     * @param {number} [params.skip=0]
     * @param {number} [params.limit=0]
     * @param {string} [params.sortKey]
     * @param {number} [params.sortValue]
     * @param {string} [params.createdFrom]
     * @param {string} [params.createdTo]
     * @param {string} [params.campaign_id]
     * @param {string} [params.user_id]
     * @param {boolean} [params.onlyOverdue]
     * @param {boolean} [params.onlyUpcoming]
     * @param {boolean} [params.sortByDeliverablesDue]
     * @param {boolean} [params.creatorActionRequired]
     * @param {boolean} [params.allowContentApproval]
     * @param {string} [params.campaignInvitationStatus]
     * @param {boolean} [params.completedCampaignBrief]
     * @param {boolean} [params.hasMilestones]
     * @param {boolean} [params.hasOverdueMilestones]
     * @param {boolean} [params.onlyCompleteMilestones]
     * @param {boolean} [params.hasUpcomingMilestones]
     * @param {boolean} [params.hasMilestonePaymentsDue]
     * @param {boolean} [params.sortByAgreementActionRequired]
     * @param {boolean} [params.onlyUpcomingMilestonesAndAssignments]
     * @param {boolean} [params.onlyUpcomingMilestonesAndAssignments]
     * @param {string} [params.status] - Comma-separated string. Enum: ???
     * @param {string} [params.lemon8ContentManager] - No longer used?
     */
    getAssignments: build.query({
      query: ({
        skip = 0,
        limit = 0,
        calculate = "contentCounts deliverableMetrics agreementMetrics",
        populate = "user_id campaign_id brand_id",
        ...params
      }) => ({
        url: "assignments",
        params: {
          skip,
          limit,
          calculate,
          populate,
          ...params,
        },
      }),
      // TODO: @segmentation ensure boolean support works with new param format. should only send boolean param if its actually a bool
      // const addPropertyIfBoolean = (obj, key, value) => {
      //   if (typeof value === "boolean") {
      //     obj[key] = value;
      //   }
      // };
      // const params = {
      //   skip: 0,
      //   limit: 0,
      //   calculate,
      //   populate,
      // };
      // addPropertyIfBoolean(
      //   params,
      //   "hasMilestonePaymentsDue",
      //   hasMilestonePaymentsDue,
      // );
      // addPropertyIfBoolean(
      //   params,
      //   "onlyUpcomingMilestonesAndAssignments",
      //   onlyUpcomingMilestonesAndAssignments,
      // );

      // return {
      //   url: "assignments",
      //   params,
      // };
      providesTags: (result) => [
        ...providesListTags(result?.results, "Assignments"),
        { type: "Milestones", id: "LIST" },
      ],
      keepUnusedDataFor: 60 * 60 * 3,
    }),
    getAssignment: build.query({
      query: ({
        _id,
        populate = "user_id campaign_id brand_id",
        calculate = "contentCounts deliverableMetrics agreementMetrics",
      }) => ({
        url: "assignment",
        params: {
          _id,
          populate,
          calculate,
        },
      }),
      providesTags: (_result, _error, id) => [
        { type: "Assignments", id },
        { type: "Assignments", id: "LIST" },
        { type: "Milestones", id: "LIST" },
      ],
    }),
    // API params
    //   const campaign_id = req.cpString("campaign_id");
    // let populate = req.cpString("populate", "");
    // const user_id = req.cpString("user_id");
    // const status = req.cpString("status");
    // const rate = req.cpNumber("rate");
    // const dateCompleted = req.cpDate("dateCompleted");
    // const nextPayoutDate = req.cpDate("nextPayoutDate");
    // const allowContentApproval = req.cpBoolean("allowContentApproval");
    createAssignment: build.mutation({
      query: ({
        campaign_id,
        user_id,
        status,
        rate,
        dateCompleted,
        nextPayoutDate,
        allowContentApproval,
      }) => ({
        url: "assignment",
        method: "POST",
        body: {
          campaign_id,
          user_id,
          status,
          rate,
          dateCompleted,
          nextPayoutDate,
          allowContentApproval,
        },
      }),
      invalidatesTags: (result, _error) => [
        { type: "Assignments", id: result?.result?._id },
        { type: "Assignments", id: "LIST" },
        { type: "Brands", id: result?.result?.brand_id },
        { type: "Creators", id: result?.result?.user_id },
        { type: "Campaigns", id: result?.result?.campaign_id },
        { type: "Balances", id: "LIST" },
      ],
    }),
    /**
     * Updates assignment based on the provided parameters.
     *
     * @param {Object} [params]
     * @param {string} params._id
     * @param {number} [params.rate]
     * @param {string} [params.status]
     * @param {string} [params.agencyCreatorName]
     * @param {Date} [params.nextPayoutDate]
     * @param {boolean} [params.approveInvite]
     * @param {boolean} [params.allowContentApproval]
     * @param {string} [params.campaignInvitationStatus] - Enum Can be one of "pending", "accepted", "declined"
     * @param {string} [params.lemon8Username]
     * @param {string} [params.lemon8ContentManager]
     * @param {string} [params.fullName]
     * @param {boolean} [params.completedCampaignBrief]
     * @param {number} [params.offPlatformAmount]
     * @param {string} [params.offPlatformReferenceId]
     * @param {string} [params.offPlatformPlatform]
     * @param {Date} [params.offPlatformCreated]
     * @param {string} [params.offPlatformMilestone_id]
     * @param {string} [params.offPlatformCreatedByAdminEmail]
     * @param {string} [params.calculate]
     * @param {string} [params.populate]
     */
    updateAssignment: build.mutation({
      query: (params) => ({
        url: "assignment",
        method: "PUT",
        body: params,
      }),
      invalidatesTags: (result, _error, params) => [
        { type: "Assignments", id: params?._id },
        {
          type: "Brands",
          id: result?.result?.brand_id?._id || result?.result?.brand_id,
        },
        {
          type: "Campaigns",
          id: result?.result?.campaign_id?._id || result?.result?.campaign_id,
        },
        { type: "Milestones", id: "LIST" },
        { type: "Balances", id: "LIST" },
      ],
    }),
    removeAssignment: build.mutation({
      query: ({ _id }) => ({
        url: `assignment/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _error, assignment_id) => [
        { type: "Assignments", id: assignment_id },
        { type: "Assignments", id: "LIST" },
        { type: "Brands", id: result?.result?.brand_id },
        { type: "Creators", id: result?.result?.user_id },
        { type: "Campaigns", id: result?.result?.campaign_id },
        { type: "Balances", id: "LIST" },
      ],
    }),

    getMilestone: build.query({
      query: (milestone_id) => ({
        url: `assignment/readOneMilestone/${milestone_id}`,
        params: {
          calculate: "contentCounts deliverableMetrics",
        },
        method: "GET",
      }),
      providesTags: (_result, _error, milestone_id) => [
        { type: "Milestones", id: milestone_id },
      ],
      transformResponse(response) {
        // @ts-ignore
        return response?.result;
      },
    }),
    createAssignmentMilestone: build.mutation({
      query: ({ assignment_id, milestone }) => ({
        url: "assignment/addMilestone",
        method: "PUT",
        body: { _id: assignment_id, ...milestone },
      }),
      invalidatesTags: (result, _error, { assignment_id }) => {
        return [
          { type: "Assignments", id: assignment_id },
          { type: "Assignments", id: "LIST" },
          { type: "Milestones", id: "LIST" },
          { type: "Brands", id: result?.result?.brand_id },
          { type: "Creators", id: result?.result?.user_id },
          { type: "Campaigns", id: result?.result?.campaign_id },
          { type: "Balances", id: "LIST" },
        ];
      },
    }),
    updateAssignmentMilestone: build.mutation({
      query: ({ assignment_id, milestone_id, changedValues }) => ({
        url: "assignment/editMilestone",
        method: "PUT",
        body: { _id: assignment_id, milestone_id, ...changedValues },
      }),
      invalidatesTags: (result, _error, { assignment_id, milestone_id }) => {
        return [
          { type: "Assignments", id: assignment_id },
          { type: "Assignments", id: "LIST" },
          { type: "Milestones", id: milestone_id },
          { type: "Milestones", id: "LIST" },
          { type: "Brands", id: result?.result?.brand_id },
          { type: "Creators", id: result?.result?.user_id },
          { type: "Campaigns", id: result?.result?.campaign_id },
          { type: "Balances", id: "LIST" },
        ];
      },
    }),
    recordOffPlatformPayment: build.mutation({
      query: ({ assignment_id, offPlatformMilestone_id, payment }) => ({
        url: "assignment",
        method: "PUT",
        // TODO: correct body
        body: { _id: assignment_id, offPlatformMilestone_id, ...payment },
      }),
      invalidatesTags: (
        result,
        _error,
        { assignment_id, offPlatformMilestone_id },
      ) => [
        { type: "Assignments", id: assignment_id },
        { type: "Assignments", id: "LIST" },
        { type: "Milestones", id: offPlatformMilestone_id },
        { type: "Brands", id: result?.result?.brand_id },
        { type: "Creators", id: result?.result?.user_id },
        { type: "Campaigns", id: result?.result?.campaign_id },
        { type: "Balances", id: "LIST" },
      ],
    }),
    //* off platform payments should hit the update assignment endpoint for some reason, NOT the create transfer API
    createTransfer: build.mutation({
      query: ({ assignment_id, milestone_id, payment }) => ({
        url: "assignment/createTransfer",
        method: "POST",
        body: { _id: assignment_id, milestone_id, ...payment },
      }),
      invalidatesTags: (result, _error, { assignment_id, milestone_id }) => [
        { type: "Assignments", id: assignment_id },
        { type: "Assignments", id: "LIST" },
        { type: "Milestones", id: milestone_id },
        { type: "Brands", id: result?.result?.brand_id },
        { type: "Creators", id: result?.result?.user_id },
        { type: "Transfers", id: "LIST" },
        { type: "Campaigns", id: result?.result?.campaign_id },
        { type: "Balances", id: "LIST" },
      ],
    }),

    /**
     * Fetches creator transfers sorted by transaction date based on the provided parameters.
     *
     * @param {Object} params
     * @param {number} [params.skip=0]
     * @param {number} [params.limit=0]
     * @param {string} [params.assignment_id]
     * @param {string} [params.brand_id]
     * @param {string} [params.campaign_id]
     * @param {string} [params.user_id]
     * @param {string} [params.milestone_id]
     * @param {string} [params.stripeCustomerId]
     * @param {string} [params.createdFrom]
     * @param {string} [params.createdTo]
     * @param {boolean} [params.interweaveReversals] - Include reversed transactions.
     */
    getTransfers: build.query({
      query: ({ skip = 0, limit = 0, ...params }) => ({
        url: "transfers",
        params: {
          skip,
          limit,
          ...params,
        },
      }),
      providesTags: (result) => providesListTags(result?.results, "Transfers"),
      keepUnusedDataFor: 60 * 60 * 3,
    }),

    // TODO: delete milestone
    // * Client Invoices

    /**
     * Fetches invoices based on the provided parameters.
     * @param {Object} params
     * @param {number} [params.skip=0]
     * @param {number} [params.limit=0]
     * @param {string} [params.campaign_id]
     * @param {string} [params.brand_id]
     * @param {string} [params.shortId]
     * @param {string} [params.invoiceId]
     * @param {string} [params.EmailStatus]
     * @param {string} [params.status]
     * @param {string} [params.sortKey]
     * @param {number} [params.sortValue]
     * @param {string} [params.DueDateFrom]
     * @param {string} [params.DueDateTo]
     * @param {string} [params.createdFrom]
     * @param {string} [params.createdTo]
     * @param {string} [params.populate] - Can populate `campaign_id`, `brand_id`
     */
    getInvoices: build.query({
      query: ({
        skip = 0,
        limit = 0,
        populate = "campaign_id brand_id",
        ...params
      }) => ({
        url: "invoices",
        params: {
          skip,
          limit,
          populate,
          ...params,
        },
      }),
      providesTags: (result) => providesListTags(result?.results, "Invoices"),
      keepUnusedDataFor: 60 * 60 * 3,
    }),

    getInvoice: build.query({
      query: ({ _id, ...params }) => ({
        url: "invoice",
        params: {
          _id,
          ...params,
        },
      }),
      providesTags: (_result, _error, id) => [{ type: "Invoices", id }],
    }),

    /**
     * Fetches transactions sorted by transaction date based on the provided parameters.
     *
     * @param {Object} params
     * @param {number} [params.skip=0]
     * @param {number} [params.limit=0]
     * @param {string} [params.assignment_id]
     * @param {string} [params.brand_id]
     * @param {string} [params.campaign_id]
     * @param {string} [params.user_id]
     * @param {string} [params.milestone_id]
     * @param {string} [params.stripeCustomerId]
     * @param {string} [params.createdFrom]
     * @param {string} [params.createdTo]
     * @param {boolean} [params.interweaveReversals] - Include reversed transactions.
     */
    getTransactions: build.query({
      query: ({ skip = 0, limit = 0, ...params }) => ({
        url: "transactions",
        params: {
          skip,
          limit,
          ...params,
        },
      }),
      providesTags: (result) =>
        providesListTags(result?.results, "Transactions"),
      keepUnusedDataFor: 60 * 60 * 3,
    }),

    // * Deliverables
    getDeliverables: build.query({
      query: ({
        milestone_id,
        assignment_id,
        campaign_id,
        user_id,
        populate,
      }) => ({
        url: "deliverables",
        params: {
          milestone_id,
          assignment_id,
          campaign_id,
          user_id,
          populate,
          skip: 0,
          limit: 0,
        },
      }),
      providesTags: (result) =>
        providesListTags(result?.results, "Deliverables"),
    }),
    getDeliverable: build.query({
      query: (id) => ({
        url: "deliverable",
        params: {
          _id: id,
        },
      }),
      providesTags: (_result, _error, id) => {
        return [{ type: "Deliverables", id }];
      },
      transformResponse(response) {
        // @ts-ignore
        return response?.result;
      },
    }),
    createDeliverable: build.mutation({
      query: (deliverable) => ({
        url: "deliverable",
        method: "POST",
        body: objectToFormData(deliverable),
        formData: true,
      }),
      invalidatesTags: (result, _error, _deliverable) => [
        { type: "Deliverables", id: "LIST" },
        { type: "Milestones", id: result?.milestone_id },
        { type: "Assignments", id: "LIST" },
        { type: "Assignments", id: result?.assignment_id },
      ],
    }),
    updateDeliverable: build.mutation({
      query: (deliverable) => ({
        url: "deliverable",
        method: "PUT",
        body: objectToFormData(deliverable),
        formData: true,
      }),
      invalidatesTags: (result, _error, { _id }) => {
        return [
          { type: "Deliverables", id: _id },
          { type: "Deliverables", id: "LIST" },
          { type: "Milestones", id: result?.milestone_id },
          { type: "Assignments", id: "LIST" },
          { type: "Assignments", id: result?.assignment_id },
        ];
      },
    }),
    deleteDeliverable: build.mutation({
      query: (deliverable) => ({
        url: `deliverable/${deliverable?._id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, deliverable) => [
        { type: "Deliverables", id: deliverable?._id },
        { type: "Deliverables", id: "LIST" },
        { type: "Milestones", id: deliverable?.milestone_id },
        { type: "Assignments", id: "LIST" },
        { type: "Assignments", id: deliverable?.assignment_id },
      ],
    }),

    // agreements
    getAgreements: build.query({
      query: (filter) => ({
        url: "agreements",
        params: {
          skip: 0,
          limit: 0,
          populate: "user_id campaign_id assignment_id brand_id",
          ...filter,
        },
      }),
      transformResponse(response) {
        // @ts-ignore
        return response?.results;
      },
      providesTags: (result) => providesListTags(result, "Agreements"),
      keepUnusedDataFor: 60 * 60 * 3,
    }),
    getAgreement: build.query({
      query: (id) => ({
        url: "agreement",
        params: {
          _id: id,
          populate: "user_id campaign_id assignment_id brand_id",
        },
      }),
      transformResponse(response) {
        // @ts-ignore
        return response?.result;
      },
      providesTags: (_result, _error, id) => [
        { type: "Agreements", id },
        { type: "Agreements", id: "LIST" },
      ],
    }),
    createAgreement: build.mutation({
      query: ({ files, ...agreementData }) => {
        const formData = new FormData();

        // Append agreement data to form data
        Object.keys(agreementData).forEach((key) => {
          formData.append(key, agreementData[key]);
        });

        // Append files to form data
        [...files]?.forEach((file, _index) => {
          formData.append("files", file);
        });

        return {
          url: "/agreement",
          method: "POST",
          body: formData,
          formData: true, // sets multipart/form-data content-type header
        };
      },
      invalidatesTags: (result, _error) => [
        { type: "Creators", id: result?.result?.user_id },
        { type: "Campaigns", id: result?.result?.campaign_id },
        { type: "Assignments", id: result?.result?.assignment_id },
        { type: "Brands", id: result?.result?.brand_id },
        { type: "Agreements", id: result?.result?._id },
        { type: "Agreements", id: "LIST" },
      ],
    }),
    updateAgreement: build.mutation({
      query: ({ agreement_id, ...changedValues }) => ({
        url: "agreement",
        method: "PUT",
        body: { _id: agreement_id, ...changedValues },
      }),
      invalidatesTags: (result, _error) => {
        return [
          { type: "Creators", id: result?.result?.user_id },
          { type: "Campaigns", id: result?.result?.campaign_id },
          { type: "Assignments", id: result?.result?.assignment_id },
          { type: "Brands", id: result?.result?.brand_id },
          { type: "Agreements", id: result?.result?._id },
          { type: "Agreements", id: "LIST" },
        ];
      },
    }),
    markAgreementSent: build.mutation({
      query: (agreement_id) => ({
        url: "agreement",
        method: "PUT",
        body: { _id: agreement_id, adminHasSent: true },
      }),
      invalidatesTags: (result, _error) => {
        return [
          { type: "Creators", id: result?.result?.user_id },
          { type: "Campaigns", id: result?.result?.campaign_id },
          { type: "Assignments", id: result?.result?.assignment_id },
          { type: "Brands", id: result?.result?.brand_id },
          { type: "Agreements", id: result?.result?._id },
          { type: "Agreements", id: "LIST" },
        ];
      },
    }),
    markAgreementSigned: build.mutation({
      query: (agreement_id) => ({
        url: "agreement",
        method: "PUT",
        body: { _id: agreement_id, adminHasSigned: true },
      }),
      invalidatesTags: (result, _error) => {
        return [
          { type: "Creators", id: result?.result?.user_id },
          { type: "Campaigns", id: result?.result?.campaign_id },
          { type: "Assignments", id: result?.result?.assignment_id },
          { type: "Brands", id: result?.result?.brand_id },
          { type: "Agreements", id: result?.result?._id },
          { type: "Agreements", id: "LIST" },
        ];
      },
    }),
  }),
});

// @ts-ignore
export const {
  useGetAdminsQuery,

  useGetCreatorQuery,
  useGetCreatorsQuery,
  useUpdateCreatorMutation,
  useInviteCreatorMutation,

  useGetCampaignsQuery,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  // useUpdateCampaignMutation,

  useGetBrandQuery,
  useGetBrandsQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation,

  useGetBrandUserQuery,
  useGetBrandUsersQuery,
  useInviteBrandUserMutation,
  useUpdateBrandUserMutation,

  useGetAssignmentsQuery,
  useGetAssignmentQuery,
  useCreateAssignmentMutation,
  useUpdateAssignmentMutation,
  useRemoveAssignmentMutation,

  useGetMilestoneQuery,
  useCreateAssignmentMilestoneMutation,
  useUpdateAssignmentMilestoneMutation,
  useRecordOffPlatformPaymentMutation,
  useCreateTransferMutation,

  useGetTransfersQuery,

  useGetTransactionsQuery,

  useGetDeliverableQuery,
  useGetDeliverablesQuery,
  useCreateDeliverableMutation,
  useUpdateDeliverableMutation,
  useDeleteDeliverableMutation,

  useGetAgreementsQuery,
  useGetAgreementQuery,
  useCreateAgreementMutation,
  useUpdateAgreementMutation,
  useMarkAgreementSentMutation,
  useMarkAgreementSignedMutation,
} = apiSlice;
