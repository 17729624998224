import { CreatorAgreements } from "@/features/creators/components/CreatorAgreements";
import {
  CreatorAssignments,
  CreatorMilestones,
  CreatorTransfers,
} from "features/creators/components";

export const creatorTabOptions = [
  {
    label: "Campaign Assignments",
    id: "campaigns",
    Pane: CreatorAssignments,
    to: "../campaigns",
  },
  {
    label: "Milestones",
    id: "milestones",
    Pane: CreatorMilestones,
    to: "../milestones",
  },
  {
    label: "Transfers",
    id: "transfers",
    Pane: CreatorTransfers,
    to: "../transfers",
  },
  {
    label: "Agreements",
    id: "agreements",
    Pane: CreatorAgreements,
    to: "../agreements",
  },
];
