import { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import Loader from "@/components/Loader";
import { getLoggedInUser } from "modules/Login/actions";

const PublicPageLayout = ({
  isAuthenticated,
  loadingAuth,
  getLoggedInUser,
  ...props
}) => {
  const navigate = useNavigate();
  const startedInitialCheck = useRef(false);
  const [finishedAuthCheck, setFinishedAuthCheck] = useState(false);
  const [cleared, setCleared] = useState(false);

  useEffect(() => {
    const checkAuth = async() => {
      if (!startedInitialCheck.current) {
        startedInitialCheck.current = true;
        console.debug("pub starting");
        try {
          await getLoggedInUser();
          console.debug("pub getLoggedInUser success");
        } catch (error) {
          console.debug("pub getLoggedInUser error", error);
        } finally {
          setFinishedAuthCheck(true);
        }
      }
    };

    checkAuth();
  }, [getLoggedInUser]);

  useEffect(() => {
    if (!startedInitialCheck.current || !finishedAuthCheck || loadingAuth) {
      console.debug("pub not done checking auth");
      return;
    }
    console.debug("pub checking if redirect needed");
    if (isAuthenticated) {
      console.debug("pub authed navigating to dashboard");
      navigate("/dashboard");
    } else {
      setCleared(true);
    }
  }, [isAuthenticated, loadingAuth, navigate, finishedAuthCheck]);

  return (
    <>
      {cleared ? (
        <>
          <main className="d-flex vh-100 align-items-center">
            <Outlet />
          </main>
        </>
      ) : (
        <Loader solid="true" />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.login.isAuthenticated,
    loadingAuth: state.login.loading,
  };
};
const ConnectedPublicPageLayout = connect(mapStateToProps, { getLoggedInUser })(
  PublicPageLayout,
);
export { ConnectedPublicPageLayout as PublicPageLayout };
