import { Badge, Spinner } from "reactstrap";

export const GridRowCountIndicator = ({
  isLoading,
  isFetching,
  filteredCount,
}) => {
  let text = "Loading...";
  let color = "secondary";
  if (isLoading) {
    text = "Loading...";
    color = "secondary";
  } else if (isFetching) {
    text = "Refreshing data...";
    color = "info";
  } else if (filteredCount != null) {
    text = `${filteredCount} items`;
    color = "secondary";
  } else {
    text = "No data";
    color = "secondary";
  }

  return (
    <Badge color={color} pill>
      <span className="data-grid__footer__data__row-count__count d-flex align-items-center gap-2">
        {(isLoading || isFetching) && <Spinner size="sm" />}
        {text}
      </span>
    </Badge>
  );
};
