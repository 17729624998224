import { showToast } from "components/Toasts/helpers/showToast";

import { actionCreator, privateHeaders } from "../../../actions";
import {
  GET_POSTS_ERROR,
  READ_MANY_POSTS_URL,
} from "../../ContentQueue/constants";
import {
  CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR,
  CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_SUCCESS,
  CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_URL,
  CREATE_ONE_CAMPAIGN_URL,
  CREATE_ONE_MILESTONE_URL,
  CREATE_ONE_TRANSFER_URL,
  DELETE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR,
  DELETE_CAMPAIGN_CREATOR_ASSIGNMENT_SUCCESS,
  DELETE_ONE_CAMPAIGN_CREATOR_ASSIGNMENT_URL,
  GET_CAMPAIGNS_ERROR,
  GET_CAMPAIGN_ERROR,
  PAY_MILESTONE_ERROR,
  PAY_MILESTONE_SUCCESS,
  READ_MANY_CAMPAIGNS_URL,
  READ_MANY_CAMPAIGN_CREATOR_ASSIGNMENTS_URL,
  READ_ONE_CAMPAIGN_URL,
  SAVE_CAMPAIGN_CREATOR_ASSIGNMENTS_ERROR,
  SAVE_CAMPAIGN_CREATOR_ASSIGNMENTS_SUCCESS,
  SAVE_CAMPAIGN_ERROR,
  SAVE_CAMPAIGN_SUCCESS,
  SAVE_MILESTONE_ERROR,
  SAVE_MILESTONE_SUCCESS,
  UPDATE_ONE_CAMPAIGN_CREATOR_ASSIGNMENT_URL,
  UPDATE_ONE_CAMPAIGN_URL,
  UPDATE_ONE_MILESTONE_URL,
  campaignsActionTypes,
} from "../constants";

export const getCampaigns = (query = {}) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.getCampaigns.REQUEST));
    try {
      let url = new URL(READ_MANY_CAMPAIGNS_URL);
      const params = url.searchParams;
      params.set("skip", "0");
      params.set("limit", "0");
      params.set("calculate", "assignmentBalances");
      params.set("populate", "brand_id");
      // apply query
      Object.keys(query)?.forEach((key) => {
        params.set(key, query[key]);
      });
      url = new URL(url);
      const campaignsRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const campaigns = await fetch(campaignsRequest);
      if (!campaigns || !campaigns.ok || campaigns.status !== 200) {
        throw new Error(GET_CAMPAIGNS_ERROR);
      } else {
        const campaignsJson = await campaigns.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.getCampaigns.SUCCESS,
            campaignsJson,
          ),
        );
      }
    } catch (error) {
      // TODO: display vague error message to user. for now, showing actual error message as debug assist
      dispatch(
        actionCreator(
          campaignsActionTypes.getCampaigns.FAILURE,
          error?.message || GET_CAMPAIGNS_ERROR,
        ),
      );
    }
  };
};

export const getCampaign = (campaign_id) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.getCampaign.REQUEST));
    try {
      if (!campaign_id) throw new Error("No campaign_id to get");
      let url = new URL(READ_ONE_CAMPAIGN_URL);
      const params = url.searchParams;
      params.append("_id", campaign_id);
      params.append("calculate", "assignmentBalances");
      params.append("populate", "brand_id");
      // TODO: campaign populates
      // params.append("populate", "assignmentBalances");
      url = new URL(url);
      const campaignRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const campaign = await fetch(campaignRequest);
      if (!campaign || !campaign.ok || campaign.status !== 200) {
        throw new Error(GET_CAMPAIGN_ERROR);
      } else {
        const campaignJson = await campaign.json();
        dispatch(
          actionCreator(campaignsActionTypes.getCampaign.SUCCESS, campaignJson),
        );
      }
    } catch (error) {
      // TODO: display vague error message to user. for now, showing actual error message as debug assist
      dispatch(
        actionCreator(
          campaignsActionTypes.getCampaign.FAILURE,
          error?.message || GET_CAMPAIGN_ERROR,
        ),
      );
    }
  };
};

/**
 * Action creator function that creates a new campaign on the server.
 * @param {Object} campaign - An object containing the details of the campaign to be created.
 * @returns {Function} - A redux-thunk function that dispatches actions to the store.
 */
export const createCampaign = (campaign) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.createCampaign.REQUEST));
    let url = new URL(CREATE_ONE_CAMPAIGN_URL);
    const params = url.searchParams;
    params.append("calculate", "assignmentBalances");
    params.append("populate", "brand_id");
    url = url = new URL(url);
    try {
      const createCampaignRequest = new Request(url, {
        method: "POST",
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(campaign),
      });
      const createCampaignResponse = await fetch(createCampaignRequest);
      if (!createCampaignResponse || createCampaignResponse.status !== 200) {
        throw new Error();
      } else {
        const createCampaignJson = await createCampaignResponse.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.createCampaign.SUCCESS,
            createCampaignJson,
          ),
        );
        showToast({ message: SAVE_CAMPAIGN_SUCCESS, type: "success" });

        return Promise.resolve(createCampaignJson?.result);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_CAMPAIGN_ERROR}: ${error.message}`
        : SAVE_CAMPAIGN_ERROR;
      dispatch(
        actionCreator(campaignsActionTypes.createCampaign.FAILURE, message),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const getCampaignCreatorAssignments = (campaign_id) => {
  return async (dispatch) => {
    dispatch(
      actionCreator(campaignsActionTypes.getCampaignCreatorAssignments.REQUEST),
    );
    try {
      let url = new URL(READ_MANY_CAMPAIGN_CREATOR_ASSIGNMENTS_URL);
      const params = url.searchParams;
      params.append("skip", "0");
      params.append("limit", "0");
      params.append("campaign_id", campaign_id);
      params.append("calculate", "contentCounts deliverableMetrics");
      params.append("populate", "user_id");
      url = new URL(url);
      const campaignsRequest = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const campaigns = await fetch(campaignsRequest);
      if (!campaigns || !campaigns.ok || campaigns.status !== 200) {
        throw new Error(GET_CAMPAIGNS_ERROR);
      } else {
        const campaignsJson = await campaigns.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.getCampaignCreatorAssignments.SUCCESS,
            campaignsJson,
          ),
        );
      }
    } catch (error) {
      // TODO: display vague error message to user. for now, showing actual error message as debug assist
      dispatch(
        actionCreator(
          campaignsActionTypes.getCampaignCreatorAssignments.FAILURE,
          error?.message || GET_CAMPAIGNS_ERROR,
        ),
      );
    }
  };
};

export const createCampaignCreatorAssignment = (assignment) => {
  return async (dispatch) => {
    dispatch(
      actionCreator(
        campaignsActionTypes.createCampaignCreatorAssignment.REQUEST,
      ),
    );
    try {
      assignment.calculate = "contentCounts";
      assignment.populate = "user_id";
      const createAssignmentRequest = new Request(
        CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_URL,
        {
          method: "POST",
          credentials: "include",
          headers: privateHeaders(),
          body: JSON.stringify(assignment),
        },
      );
      const createAssignmentResponse = await fetch(createAssignmentRequest);
      if (
        !createAssignmentResponse ||
        createAssignmentResponse.status !== 201
      ) {
        throw new Error();
      } else {
        const assignmentJson = await createAssignmentResponse.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.createCampaignCreatorAssignment.SUCCESS,
            assignmentJson,
          ),
        );
        showToast({
          message: CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_SUCCESS,
          type: "success",
        });
        dispatch(getCampaign(assignment?.campaign_id));
        return Promise.resolve(assignmentJson?.result);
      }
    } catch (error) {
      const message = error?.message
        ? `${CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR}: ${error.message}`
        : CREATE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR;
      dispatch(
        actionCreator(
          campaignsActionTypes.createCampaignCreatorAssignment.FAILURE,
          message,
        ),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const removeCampaignCreatorAssignment = (assignment) => {
  return async (dispatch) => {
    dispatch(
      actionCreator(
        campaignsActionTypes.removeCampaignCreatorAssignment.REQUEST,
      ),
    );
    // prompt for confirmation
    const confirmPrompt = assignment?.user_id?.name
      ? `Are you sure you want to remove ${assignment?.user_id?.name} from this campaign?`
      : "Are you sure you want to remove this creator from this campaign?";
    if (!window.confirm(confirmPrompt)) {
      dispatch(
        actionCreator(
          campaignsActionTypes.removeCampaignCreatorAssignment.FAILURE,
        ),
      );
      return Promise.resolve();
    }
    try {
      if (!assignment?._id) throw new Error("No assignment._id to remove");
      const campaign_id =
        assignment?.campaign_id?._id || assignment?.campaign_id;
      if (!campaign_id) throw new Error("campaign_id not found on assignment");
      const removeAssignmentRequest = new Request(
        DELETE_ONE_CAMPAIGN_CREATOR_ASSIGNMENT_URL.replace(
          ":_id",
          assignment._id,
        ),
        {
          method: "DELETE",
          credentials: "include",
          headers: privateHeaders(),
        },
      );
      const response = await fetch(removeAssignmentRequest);
      if (!response?.ok) {
        throw new Error();
      } else {
        dispatch(
          actionCreator(
            campaignsActionTypes.removeCampaignCreatorAssignment.SUCCESS,
          ),
        );
        showToast({
          message: DELETE_CAMPAIGN_CREATOR_ASSIGNMENT_SUCCESS,
          type: "success",
        });
        dispatch(changeDisplayedAssignment(null));
        dispatch(getCampaign(campaign_id));
        dispatch(getCampaignCreatorAssignments(campaign_id));
        return Promise.resolve();
      }
    } catch (error) {
      const message = error?.message
        ? `${DELETE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR}: ${error.message}`
        : DELETE_CAMPAIGN_CREATOR_ASSIGNMENT_ERROR;
      dispatch(
        actionCreator(
          campaignsActionTypes.removeCampaignCreatorAssignment.FAILURE,
          message,
        ),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const getCampaignPosts = (campaign_id) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.getCampaignPosts.REQUEST));
    try {
      let url = new URL(READ_MANY_POSTS_URL);
      const params = url.searchParams;
      params.append("skip", "0");
      params.append("limit", "0");
      params.append("campaign_id", campaign_id);
      params.append("calculate", "contentCounts");
      params.append("populate", "user_id");
      url = new URL(url);
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const posts = await fetch(request);
      if (!posts || !posts.ok || posts.status !== 200) {
        throw new Error(GET_POSTS_ERROR);
      } else {
        const campaignsJson = await posts.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.getCampaignPosts.SUCCESS,
            campaignsJson,
          ),
        );
      }
    } catch (error) {
      // TODO: display vague error message to user. for now, showing actual error message as debug assist
      dispatch(
        actionCreator(
          campaignsActionTypes.getCampaignPosts.FAILURE,
          error?.message || GET_POSTS_ERROR,
        ),
      );
    }
  };
};

export const updateCampaign = (campaign) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.updateCampaign.REQUEST));
    try {
      if (!campaign?._id) throw new Error("No campaign_id to update");
      campaign.calculate = "assignmentBalances";
      campaign.populate = "brand_id";
      const updateCampaignRequest = new Request(UPDATE_ONE_CAMPAIGN_URL, {
        method: "PUT",
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(campaign),
      });
      const updateCampaignResponse = await fetch(updateCampaignRequest);
      if (!updateCampaignResponse || updateCampaignResponse.status !== 200) {
        throw new Error();
      } else {
        const createCampaignJson = await updateCampaignResponse.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.updateCampaign.SUCCESS,
            createCampaignJson,
          ),
        );
        showToast({ message: SAVE_CAMPAIGN_SUCCESS, type: "success" });
        return Promise.resolve(createCampaignJson);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_CAMPAIGN_ERROR}: ${error.message}`
        : SAVE_CAMPAIGN_ERROR;
      dispatch(
        actionCreator(campaignsActionTypes.updateCampaign.FAILURE, message),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const updateCampaignAssignment = (assignment) => {
  return async (dispatch) => {
    dispatch(
      actionCreator(
        campaignsActionTypes.updateCampaignCreatorAssignment.REQUEST,
      ),
    );
    try {
      if (!assignment?._id) throw new Error("No assignment._id to update");
      assignment.populate = "user_id";
      const updateAssignmentRequest = new Request(
        UPDATE_ONE_CAMPAIGN_CREATOR_ASSIGNMENT_URL,
        {
          method: "PUT",
          credentials: "include",
          headers: privateHeaders(),
          body: JSON.stringify(assignment),
        },
      );
      const updateAssignmentResponse = await fetch(updateAssignmentRequest);
      if (
        !updateAssignmentResponse ||
        updateAssignmentResponse.status !== 200
      ) {
        throw new Error();
      } else {
        const assignmentJson = await updateAssignmentResponse.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.updateCampaignCreatorAssignment.SUCCESS,
            assignmentJson,
          ),
        );
        showToast({
          message: SAVE_CAMPAIGN_CREATOR_ASSIGNMENTS_SUCCESS,
          type: "success",
        });
        return Promise.resolve(assignmentJson);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_CAMPAIGN_CREATOR_ASSIGNMENTS_ERROR}: ${error.message}`
        : SAVE_CAMPAIGN_CREATOR_ASSIGNMENTS_ERROR;
      dispatch(
        actionCreator(
          campaignsActionTypes.updateCampaignCreatorAssignment.FAILURE,
          message,
        ),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const createMilestone = (milestone) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.createMilestone.REQUEST));
    try {
      milestone._id = milestone.assignment_id;
      milestone.populate = "user_id";
      const milestoneRequest = new Request(CREATE_ONE_MILESTONE_URL, {
        method: "PUT",
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(milestone),
      });
      const milestoneResponse = await fetch(milestoneRequest);
      if (!milestoneResponse || milestoneResponse.status !== 200) {
        throw new Error();
      } else {
        const milestoneJson = await milestoneResponse.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.createMilestone.SUCCESS,
            milestoneJson,
          ),
        );
        showToast({ message: SAVE_MILESTONE_SUCCESS, type: "success" });
        return Promise.resolve(milestoneJson);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_MILESTONE_ERROR}: ${error.message}`
        : SAVE_MILESTONE_ERROR;
      dispatch(
        actionCreator(campaignsActionTypes.createMilestone.FAILURE, message),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const updateMilestone = ({ milestone, assignment_id }) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.updateMilestone.REQUEST));
    // TODO: milestone_id?
    try {
      if (!milestone?._id) throw new Error("No milestone_id to update");
      if (!assignment_id && !milestone.assignment_id) {
        throw new Error("No assignment_id to update");
      }
      if (
        assignment_id &&
        milestone.assignment_id &&
        assignment_id !== milestone.assignment_id
      ) {
        throw new Error(
          "assignment_id and milestone.assignment_id mismatch. Please contact development team.",
        );
      }
      milestone.milestone_id = milestone?._id;
      milestone._id = assignment_id || milestone.assignment_id;
      milestone.populate = "user_id";
      const milestoneRequest = new Request(UPDATE_ONE_MILESTONE_URL, {
        method: "PUT",
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(milestone),
      });
      const milestoneResponse = await fetch(milestoneRequest);
      if (!milestoneResponse || milestoneResponse.status !== 200) {
        throw new Error();
      } else {
        const milestoneJson = await milestoneResponse.json();
        dispatch(
          actionCreator(
            campaignsActionTypes.updateMilestone.SUCCESS,
            milestoneJson,
          ),
        );
        showToast({ message: SAVE_MILESTONE_SUCCESS, type: "success" });
        return Promise.resolve(milestoneJson);
      }
    } catch (error) {
      const message = error?.message
        ? `${SAVE_MILESTONE_ERROR}: ${error.message}`
        : SAVE_MILESTONE_ERROR;
      dispatch(
        actionCreator(campaignsActionTypes.updateMilestone.FAILURE, message),
      );
      showToast({ message, type: "error" });
      return Promise.reject(message);
    }
  };
};

export const makePayment = ({ milestone, assignment_id }) => {
  return async (dispatch) => {
    dispatch(actionCreator(campaignsActionTypes.makePayment.REQUEST));
    // TODO: milestone_id?
    try {
      if (!milestone?._id) throw new Error("No milestone_id to update");
      if (!assignment_id && !milestone.assignment_id) {
        throw new Error("No assignment_id to update");
      }
      if (
        assignment_id &&
        milestone.assignment_id &&
        assignment_id !== milestone.assignment_id
      ) {
        throw new Error("assignment_id and milestone.assignment_id mismatch");
      }
      if (milestone?.offPlatformPayment) {
        milestone.offPlatformMilestone_id = milestone?._id;
      } else {
        milestone.milestone_id = milestone?._id;
      }
      milestone._id = assignment_id || milestone.assignment_id;
      milestone.populate = "user_id";
      // off platform payments should hit the update assignment endpoint for some reason
      // TODO: split this up into separate actions, and fix reducer handling of response (it currently expects a payload but gets none)
      const url = milestone?.offPlatformPayment
        ? UPDATE_ONE_CAMPAIGN_CREATOR_ASSIGNMENT_URL
        : CREATE_ONE_TRANSFER_URL;
      const method = milestone?.offPlatformPayment ? "PUT" : "POST";
      const milestoneRequest = new Request(url, {
        method,
        credentials: "include",
        headers: privateHeaders(),
        body: JSON.stringify(milestone),
      });
      const milestoneResponse = await fetch(milestoneRequest);
      if (
        !milestoneResponse ||
        milestoneResponse.status < 200 ||
        milestoneResponse.status > 300
      ) {
        throw new Error();
      } else {
        dispatch(actionCreator(campaignsActionTypes.makePayment.SUCCESS));
        showToast({ message: PAY_MILESTONE_SUCCESS, type: "success" });
        return Promise.resolve();
      }
    } catch (error) {
      const message = error?.message
        ? `${PAY_MILESTONE_ERROR}: ${error.message}`
        : PAY_MILESTONE_ERROR;
      showToast({ message, type: "error" });
      dispatch(
        actionCreator(campaignsActionTypes.makePayment.FAILURE, message),
      );
      return Promise.reject(message);
    }
  };
};

export const changeDisplayedCampaign = (campaign) => {
  return (dispatch) => {
    dispatch(
      actionCreator(
        campaignsActionTypes.changeDisplayedCampaign.SUCCESS,
        campaign,
      ),
    );
  };
};

export const changeDisplayedAssignment = (assignment) => {
  return (dispatch) => {
    dispatch(
      actionCreator(
        campaignsActionTypes.changeDisplayedAssignment.SUCCESS,
        assignment,
      ),
    );
  };
};
