import { LinkIcon } from "@heroicons/react/20/solid";

import { LinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/LinkCellRenderer";

/**
 * Renders a link to a assignment based on the provided grid cell value.
 *
 * @param {object} props
 * @param {string} props.value - The ID of the assignment. Passed from ag-Grid.
 * @param {object} props.data - The row data of the assignment. Passed from ag-Grid.
 * @returns {JSX.Element} The rendered AssignmentLink component.
 */
export const AssignmentLinkCellRenderer = ({ value, data }) => {
  let content;
  let muted = true;

  console.log("link cell renderer", value, data);

  if (!value) {
    content = "";
  } else if (!data?.user_id?.name || !data?.campaign_id?.name) {
    content = "Can not display assignment data.";
  } else {
    const creatorName = data.user_id?.name;
    const campaignName = data.campaign_id?.name;
    content = (
      <span>
        {creatorName}
        <LinkIcon height={".9em"} width={".9em"} className="mx-1 mb-1" />
        {campaignName}
      </span>
    );
    muted = false;
  }

  return (
    <LinkCellRenderer
      to={`/assignments/${value}`}
      // @ts-ignore
      text={content}
      muted={muted}
    />
  );
};
