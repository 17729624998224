import { useMemo } from "react";

import DataGrid from "components/DataGrid";

import { topupsColDefs } from "./constants/columnDefs";
import { apiSlice } from "app/apiSlice";

const AllTopupsPane = ({ activeTabId, tabId, ...props }) => {
  const { data, isLoading } = apiSlice.useGetTopupsQuery();
  const stripeBalances = data?.result;
  const allTopups = useMemo(() => {
    const pendingTopups = stripeBalances?.allPendingTopups ?? [];
    const pastTopups = stripeBalances?.allPastTopups ?? [];
    return [...pendingTopups, ...pastTopups];
  }, [stripeBalances]);
  return (
    <DataGrid
      gridId="all-topups"
      borders
      idField="id"
      visible={activeTabId === tabId}
      rowData={allTopups}
      columnDefs={topupsColDefs}
      loading={isLoading}
    />
  );
};

export default AllTopupsPane;
