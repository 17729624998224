import LabelledData from "components/Forms/LabelledData";
import AgreementConfigModalButton from "features/agreements2/components/Agreement/AgreementConfigModalButton";
import AgreementSignButton from "features/agreements2/components/Agreement/AgreementSignModalButton";
import ViewAgreementFileButton from "features/agreements2/components/Agreement/ViewAgreementFileButton";
import useAgreementData from "features/agreements2/components/Agreement/hooks/useAgreementData";
import { agreementFieldDefs } from "features/agreements2/constants/agreementFieldDefs";

/**
 * Renders the fields of an agreement record, and optionally any children.
 *
 * It must be a descendent of an AgreementContext.Provider and a Formik component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} [props.children] - The children to render.
 * @returns {React.ReactNode} The rendered component.
 */
const AgreementFields = () => {
  const { agreement } = useAgreementData();

  return (
    <>
      {agreementFieldDefs?.map((def, i) => {
        return (
          <LabelledData
            key={i}
            definition={def}
            isEditing={true}
            data={agreement || {}}
          />
        );
      })}
      <div className="mt-4 d-flex flex-column align-items-start gap-4">
        <ViewAgreementFileButton agreement_id={agreement?._id} />
        <AgreementConfigModalButton />
        <AgreementSignButton />
      </div>
    </>
  );
};

export default AgreementFields;
