export const persistedEndpointNames = new Set([
  "getCreators",
  "getCampaigns",
  "getBrands",
  "getAssignments",
  "getTransfers",
  "getInvoices",
  "getTransactions",
  "getAgreements",
]);
