import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { withFaroRouterInstrumentation } from "@grafana/faro-react";

import { AgreementsListPage } from "features/agreements2/pages/AgreementsListPage";
import {
  AssignmentAssignmentsPageSection,
  AssignmentPage,
  AssignmentsListPage,
} from "features/assignments";
import {
  BrandAssignmentSection,
  BrandAssignmentsPageSection,
  BrandPage,
  BrandsListPage,
} from "features/brands";
import {
  CampaignAssignmentSection,
  CampaignAssignmentsPageSection,
  CampaignPage,
} from "features/campaigns";
import { CampaignsListPage } from "features/campaigns/pages/CampaignsListPage";
import { CreatorsListPage } from "features/creators";
import { CreatorAssignmentsPageSection } from "features/creators/pages/CreatorAssignmentsListSection";
import { CreatorPage } from "features/creators/pages/CreatorPage";
import { InvoicesListPage } from "features/invoices";
import { MilestonesListPage } from "features/milestones/pages/MilestonesListPage";
import { TransfersListPage } from "features/transfers/pages/TransfersListPage";
import Dashboard from "modules/Dashboard";
import Login from "modules/Login";
import Logout from "modules/Login/Logout";
import {
  PrivatePageLayout,
  ProtectedRouteLayout,
  PublicPageLayout,
} from "routers";

const routes = [
  {
    // Private Routes
    element: <PrivatePageLayout />,
    children: [
      {
        element: <ProtectedRouteLayout requiredPermissions={["dashboard"]} />,
        children: [
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
        ],
      },
      {
        path: "brands",
        element: <BrandsListPage />,
        children: [
          {
            path: ":brand_id",
            element: <BrandPage />,
            children: [
              {
                index: true,
                element: <Navigate to="campaigns" replace={true} />,
              },
              {
                path: ":assignmentsTab",
                element: <BrandAssignmentsPageSection />,
                children: [
                  {
                    path: ":assignment_id",
                    element: <BrandAssignmentSection />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "campaigns",
        element: <CampaignsListPage />,
        children: [
          {
            path: ":campaign_id",
            element: <CampaignPage />,
            children: [
              {
                index: true,
                element: <Navigate to="creators" replace={true} />,
              },
              {
                path: ":assignmentsTab",
                element: <CampaignAssignmentsPageSection />,
                children: [
                  {
                    path: ":assignment_id",
                    element: <CampaignAssignmentSection />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "creators",
        element: <CreatorsListPage />,
        children: [
          {
            path: ":creator_id",
            element: <CreatorPage />,
            children: [
              {
                index: true,
                element: <Navigate to="campaigns" replace={true} />,
              },
              {
                path: ":assignmentsTab",
                element: <CreatorAssignmentsPageSection />,
              },
            ],
          },
        ],
      },
      {
        path: "assignments",
        element: <AssignmentsListPage />,
        children: [
          {
            path: ":assignment_id",
            element: <AssignmentPage />,
            children: [
              {
                index: true,
                element: <Navigate to="milestones" replace={true} />,
              },
              {
                path: ":assignmentsTab",
                element: <AssignmentAssignmentsPageSection />,
              },
            ],
          },
        ],
      },
      {
        path: "milestones",
        element: <MilestonesListPage />,
      },
      {
        path: "transfers",
        element: <TransfersListPage />,
      },
      {
        path: "invoices",
        element: <InvoicesListPage />,
      },
      {
        path: "agreements",
        element: <AgreementsListPage />,
      },
    ],
  },
  // Public Routes
  {
    element: <PublicPageLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
  // 404 fallback
  {
    path: "*",
    element: <Navigate to="/dashboard" />,
  },
];

const router = createBrowserRouter(routes);
const instrumentedRouter = withFaroRouterInstrumentation(router);

export const Router = () => {
  return <RouterProvider router={instrumentedRouter} />;
};
