import { actionCreator, privateHeaders } from "actions";

import { showToast } from "components/Toasts/helpers/showToast";
import { persistor } from "store/store";

import {
  LOGIN_ERROR,
  LOGIN_URL,
  LOGOUT_URL,
  USER_URL,
  loginActionTypes,
} from "../constants";

import { encryptionKeyManager } from "services/encryption";

/**
 * Action creator function that attempts login with given credentials.
 * @param {Object} credentials - An object containing the credentials of the user to attempt login.
 * @property {String} credentials.email - The email of the user to attempt login.
 * @property {String} credentials.password - The password of the user to attempt login.
 * @returns {Function} - A redux-thunk function that dispatches actions to the store.
 */
export const login = (credentials) => {
  return async (dispatch) => {
    dispatch(actionCreator(loginActionTypes.login.REQUEST));
    // TODO: move this to a helper, and fix types

    try {
      const loginRequest = new Request(LOGIN_URL, {
        method: "POST",
        credentials: "include",
        referrerPolicy: "origin",
        headers: privateHeaders(),
        body: JSON.stringify(credentials),
      });
      const loginResponse = await fetch(loginRequest);
      if (!loginResponse || loginResponse.status !== 200) {
        if (loginResponse?.status === 401) {
          throw new Error("Invalid email or password.");
        }
        throw new Error();
      } else {
        const loginJson = await loginResponse.json();
        dispatch(
          actionCreator(loginActionTypes.login.SUCCESS, loginJson?.result),
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        actionCreator(
          loginActionTypes.login.FAILURE,
          error?.message || LOGIN_ERROR,
        ),
      );
      showToast({ message: error?.message || LOGIN_ERROR });
    }
  };
};

/**
 * Action creator function that logs out the user.
 * @returns {Function} - A redux-thunk function that dispatches actions to the store.
 */
export const logout = () => {
  return async (dispatch) => {
    dispatch(actionCreator(loginActionTypes.logout.REQUEST));
    try {
      const logoutRequest = new Request(LOGOUT_URL, {
        method: "GET",
        credentials: "include",
        referrerPolicy: "origin",
        headers: privateHeaders(),
      });
      const logoutResponse = await fetch(logoutRequest);
      if (logoutResponse?.status < 200 || logoutResponse?.status >= 300) {
        throw new Error();
      }
      encryptionKeyManager.clearKey();
      await persistor.purge();
      dispatch(actionCreator(loginActionTypes.logout.SUCCESS));
    } catch (error) {
      console.error("Error logging out: ", error?.message || error);
      dispatch(
        actionCreator(loginActionTypes.logout.FAILURE, error?.message || error),
      );
      showToast({ message: "Error logging out" });
    }
  };
};

/**
 * Action creator function that retrieves the logged in user (if there is one).
 * @returns {Function} - A redux-thunk function that dispatches actions to the store.
 */
export const getLoggedInUser = () => {
  return async (dispatch) => {
    dispatch(actionCreator(loginActionTypes.getLoggedInUser.REQUEST));
    try {
      const getLoggedInUserRequest = new Request(USER_URL, {
        method: "GET",
        credentials: "include",
        referrerPolicy: "origin",
        headers: privateHeaders(),
      });
      const getLoggedInUserResponse = await fetch(getLoggedInUserRequest);
      if (!getLoggedInUserResponse) {
        throw new Error("No response");
      }
      if (
        getLoggedInUserResponse.status === 401 ||
        getLoggedInUserResponse.status === 403
      ) {
        throw new Error("Unauthorized");
      }
      if (
        getLoggedInUserResponse.status < 200 ||
        getLoggedInUserResponse.status >= 300
      ) {
        throw new Error("Failure response");
      }
      const userJson = await getLoggedInUserResponse.json();
      if (!userJson?.result?._id) {
        throw new Error("No user returned");
      }
      dispatch(
        actionCreator(
          loginActionTypes.getLoggedInUser.SUCCESS,
          userJson?.result,
        ),
      );
      return userJson?.result;
    } catch (error) {
      console.error("Error getting logged in user: ", error?.message || error);
      dispatch(actionCreator(loginActionTypes.getLoggedInUser.FAILURE));
      dispatch(logout());
      throw error;
    }
  };
};
