import { useEffect, useCallback } from "react";

import esigEventMappings from "./esigEventMappings";

const ESIG_ORIGIN = import.meta.env.VITE_ESIG_EVENT_ORIGIN;

/**
 * Custom hook to handle events from the e-signature provider (BoldSign).
 * This hook listens for specific events from the e-signature provider and triggers a callback function when the event occurs.
 *
 * @param {string} genericEventType - The generic event type to listen for.
 * @param {Function} onEvent - The callback function to be executed when the event occurs.
 */
const useEsigEvent = (genericEventType, onEvent) => {
  const eventType = esigEventMappings[genericEventType];

  const memoizedOnEvent = useCallback(onEvent, [onEvent]);

  if (!ESIG_ORIGIN) {
    console.error("Required ENV variable VITE_ESIG_EVENT_ORIGIN is not set");
  }

  useEffect(() => {
    if (!eventType) {
      console.error(
        `Event type "${genericEventType}" not found in esigEventMappings`,
      );
      return;
    }

    const handleEvent = (event) => {
      if (event.origin !== ESIG_ORIGIN) return;
      if (event.data === eventType || event.data?.action === eventType) {
        memoizedOnEvent();
      }
    };

    window.addEventListener("message", handleEvent);
    return () => window.removeEventListener("message", handleEvent);
  }, [eventType, genericEventType, memoizedOnEvent]);
};

export default useEsigEvent;
