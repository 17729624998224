import { AssignmentAgreements } from "@/features/assignments/components/AssignmentAgreements";
import {
  AssignmentMilestones,
  AssignmentTransfers,
} from "features/assignments/components";

export const assignmentTabOptions = [
  {
    label: "Milestones",
    id: "milestones",
    Pane: AssignmentMilestones,
    to: "../milestones",
  },
  {
    label: "Transfers",
    id: "transfers",
    Pane: AssignmentTransfers,
    to: "../transfers",
  },
  {
    label: "Agreements",
    id: "agreements",
    Pane: AssignmentAgreements,
    to: "../agreements",
  },
];
