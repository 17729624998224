import { memo } from "react";

import {
  AssignmentLinkCellRenderer,
  CampaignLinkCellRenderer,
} from "components/DataGrid/components";
import { BrandLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/BrandLinkCellRenderer";
import { CreatorLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/CreatorLinkCellRenderer";
import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";

export const milestonesColDefs = [
  {
    headerName: "_id",
    field: "_id",
    type: "text",
    hide: true,
  },
  {
    headerName: "Name",
    field: "name",
    type: "text",
  },
  {
    headerName: "Status/Notes",
    field: "status",
    type: "text",
  },
  {
    // TODO: Add editable cell renderer so that priority can be changed from the grid
    headerName: "Priority",
    field: "priority",
    type: "text",
    valueFormatter: (params) => {
      return params.value?.charAt(0)?.toUpperCase() + params.value?.slice(1);
    },
    sort: "desc",
    comparator: (valueA, valueB) => {
      // TODO: @segmentation verify this works
      const priorityOrder = ["low", "medium", "high"];
      return priorityOrder.indexOf(valueA) - priorityOrder.indexOf(valueB);
    },
  },
  {
    headerName: "Payment Due Date",
    field: "nextPaymentDate",
    type: "date",
  },
  {
    headerName: "Department",
    field: "campaign_id.department",
    type: "text",
    dataSource: "campaign,assignment",
    valueGetter: (params) => {
      let text = "";
      if (params.data?.campaign_id?.department === "managed") {
        text = "Managed Services";
      }
      if (params.data?.campaign_id?.department === "talent") {
        text = "Talent";
      }
      return text;
    },
  },
  {
    headerName: "Assignment ID",
    field: "assignment_id",
    dataSource: "assignment",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Assignment",
    field: "assignment_id",
    dataSource: "assignment",
    cellRenderer: memo(AssignmentLinkCellRenderer),
    type: "text",
    initialWidth: 200,
    filterValueGetter: (params) => {
      // Makes the assignment's creator name, campaign name, and assignment ID searchable
      if (!params.data?.user_id?.name || !params.data?.campaign_id?.name) {
        return "";
      }
      return `${params.data?.user_id?.name} ${params.data?.campaign_id?.name} ${params.data?.assignment_id}`;
    },
    valueFormatter: (params) => {
      return `${params.data?.user_id?.name || "unknown"} <> ${params.data?.campaign_id?.name || "unknown"}`;
    },
  },
  {
    headerName: "Creator ID",
    field: "user_id._id",
    dataSource: "creator",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Creator",
    field: "user_id._id",
    dataSource: "creator",
    cellRenderer: memo(CreatorLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the creator's name and ID searchable
      if (!params.data?.user_id?.name) {
        return "";
      }
      return `${params.data?.user_id?.name} ${params.data?.user_id?._id}`;
    },
    valueFormatter: (params) => {
      return params.data?.user_id?.name || "";
    },
  },
  {
    headerName: "Campaign ID",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Campaign",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    filterValueGetter: (params) => {
      // Makes the campaign's name and ID searchable
      if (!params.data?.campaign_id?.name) {
        return "";
      }
      return `${params.data?.campaign_id?.name} ${params.data?.campaign_id?._id}`;
    },
    valueFormatter: (params) => {
      return params.data?.campaign_id?.name || "";
    },
  },
  {
    headerName: "Campaign Short ID",
    field: "campaign_id._id",
    dataSource: "campaign",
    // colId: "campaign.shortId", // ! if I leave this as just "campaign" it causes a crash after updating a milestone with `this.column` is null from AGGrid getColId. Probably because neither the field or colId properties are unique
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    cellRendererParams: {
      linkTextKey: "campaign_id.shortId",
    },
    filterValueGetter: (params) => {
      return params.data?.campaign_id?.shortId;
    },
    valueFormatter: (params) => {
      return params.data?.campaign_id?.shortId || "";
    },
  },
  {
    headerName: "Brand ID",
    field: "brand_id._id",
    dataSource: "brand._id",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Brand",
    field: "brand_id._id",
    dataSource: "brand._id",
    cellRenderer: memo(BrandLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the brand's name and ID searchable
      if (!params.data?.brand_id?.name) {
        return "";
      }
      return `${params.data?.brand_id?.name} ${params.data?.brand_id?._id}`;
    },
    valueFormatter: (params) => {
      return params.data?.brand_id?.name || "";
    },
  },
  {
    headerName: "Rate",
    field: "rate",
    type: "currency",
  },
  {
    headerName: "Potentially Owed",
    field: "holdBalance",
    type: "currency",
  },
  {
    headerName: "Earned",
    field: "paidBalance",
    type: "currency",
  },
  {
    headerName: "Complete?",
    field: "isComplete",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Completed Date",
    field: "dateCompleted",
    type: "date",
  },
  {
    headerName: "Release Payment?",
    field: "releasePayment",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Deliverables Complete",
    field: "__deliverableMetrics.complete",
    type: "count",
  },
  {
    headerName: "Deliverables Incomplete",
    field: "__deliverableMetrics.incomplete",
    type: "count",
  },
  {
    headerName: "Deliverables Total",
    field: "__deliverableMetrics.total",
    type: "count",
  },
  // {
  //   headerName: "Agreements Incomplete",
  //   field: "__agreementMetrics.requiredIncomplete",
  //   type: "number",
  // },
  // {
  //   headerName: "Agreements Complete",
  //   field: "__agreementMetrics.requiredComplete",
  //   type: "number",
  // },
  // {
  //   headerName: "Agreements Total",
  //   field: "__agreementMetrics.total",
  //   type: "number",
  // },
];
