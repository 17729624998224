import { matchRoutes } from "react-router-dom";
import {
  getWebInstrumentations,
  initializeFaro,
  LogLevel,
  FaroErrorBoundary,
  ReactIntegration,
  createReactRouterV6DataOptions,
} from "@grafana/faro-react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

let faroInstance = null;

export const initializeTelemetry = () => {
  try {
    const grafanaURL = import.meta.env.VITE_GRAFANA_FARO_URL;
    if (!grafanaURL) {
      throw new Error(
        "VITE_GRAFANA_FARO_URL is not defined, telemetry will not be sent",
      );
    }

    faroInstance = initializeFaro({
      url: grafanaURL,
      app: {
        // name: "Creator Portal Admin",
        name: import.meta.env.VITE_APP_NAME || "undefined",
        version: import.meta.env.VITE_APP_VERSION || "0.0.0",
        environment: import.meta.env.MODE,
      },
      instrumentations: [
        ...getWebInstrumentations(),
        new TracingInstrumentation(),
        new ReactIntegration({
          router: createReactRouterV6DataOptions({
            matchRoutes,
          }),
        }),
      ],
      sessionTracking: {
        enabled: true,
        persistent: true,
        maxSessionPersistenceTime: 30 * 60 * 1000, // 30 minutes in milliseconds
      },
    });
  } catch (error) {
    console.warn("Failed to initialize Faro:", error);
  }
};

// Custom logging functions
export const logInfo = (message, context = {}) => {
  faroInstance?.api?.pushLog([message], {
    level: LogLevel.INFO,
    context,
  });
};

export const logError = (error, context = {}) => {
  faroInstance?.api?.pushError(error, {
    context,
  });
};

// Custom metrics
export const pushMetric = (name, value, attributes = {}) => {
  faroInstance?.api?.pushMeasurement({
    name,
    value,
    attributes,
  });
};

// User context
export const setUserContext = (userId, attributes = {}) => {
  faroInstance?.api?.setUser({
    id: userId,
    attributes,
  });
};

export { FaroErrorBoundary };
