import { createRequestActionTypes } from "../../../actions";

export const READ_MANY_ASSIGNMENTS_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignments`;
export const READ_STRIPE_BALANCES_URL = `${import.meta.env.VITE_API_URL}/api/v1/stripe/balance`;
export const READ_ASSIGNMENT_TOTALS_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignment/calculateTotals`;
export const GET_FORECAST_URL = `${import.meta.env.VITE_API_URL}/api/v1/assignment/forecast`;

export const GET_STRIPE_BALANCES_ERROR = "Error getting Stripe balances";
export const GET_CREATOR_BALANCES_ERROR = "Error getting creator balances";
export const GET_MILESTONES_ERROR = "Error getting milestones";
export const GET_ASSIGNMENTS_ERROR = "Error getting assignments";
export const GET_FORECAST_ERROR = "Error getting payment forecast data";

export const dashboardActionTypes = {
  getStripeBalances: createRequestActionTypes("GET_STRIPE_BALANCES"),
  getCreatorBalances: createRequestActionTypes("GET_CREATOR_BALANCES"),
  getMilestonesUpcoming: createRequestActionTypes("GET_MILESTONES_UPCOMING"),
  getMilestonesDue: createRequestActionTypes("GET_MILESTONES_DUE"),
  getAssignmentsUpcoming: createRequestActionTypes("GET_ASSIGNMENTS_UPCOMING"),
  getAssignmentsDue: createRequestActionTypes("GET_ASSIGNMENTS_DUE"),
  getForecastData: createRequestActionTypes("GET_FORECAST_DATA"),
};
