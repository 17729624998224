import { useMarkAgreementSentMutation } from "app/apiSlice";
import { showToast } from "components/Toasts/helpers/showToast";
import { useAgreementContext } from "features/agreements2/components/Agreement/context/AgreementContext";
import useAgreementData from "features/agreements2/components/Agreement/hooks/useAgreementData";
import { useEsigEvent } from "hooks/useEsigEvent";

const AgreementConfigAndSend = () => {
  const { agreement } = useAgreementData();
  const { agreementId, setAgreementUIStatus } = useAgreementContext();

  const [markSent] = useMarkAgreementSentMutation();

  useEsigEvent("documentSent", async() => {
    try {
      const { error } = await markSent(agreementId).unwrap();
      // setAgreementUIStatus("closed");
      if (error) {
        throw new Error(error);
      }
      setAgreementUIStatus("edit");
      showToast({
        type: "success",
        message: "Agreement sent successfully",
      });
    } catch (error) {
      console.error("Error updating agreement after documentSent - ", error);
      showToast({
        type: "error",
        message:
          "Error updating agreement. The agreement was sent, but the status may not have been updated. Refresh the Agreements grid to see the latest status.",
      });
    }
  });

  return (
    <iframe
      src={agreement?.embeddedRequestUrl}
      title={`Embedded document builder for agreement ${agreement?.title}`}
      width="100%"
      // TODO: This height should be dynamic based on the height of the document
      height="850px"
      frameBorder="0"
    />
  );
};

export default AgreementConfigAndSend;
