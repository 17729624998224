import AgreementByUIState from "@/features/agreements2/components/Agreement/AgreementByUIState";
import { AgreementProvider } from "@/features/agreements2/components/Agreement/context/AgreementProvider";
import AgreementModal from "features/agreements2/components/Agreement/AgreementModal";

/**
 * Renders an Agreement component and sets up the agreement context provider.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string|null} props.agreementId - The ID of the agreement.
 * @param {Function} props.setAgreementId - The function to set the agreement ID.
 * @param {string|null} [props.creatorId] - The ID of the creator. Used when creating an agreement.
 * @param {string|null} [props.assignmentId] - The ID of the assignment. Used when creating an agreement.
 * @param {boolean} [props.isOpen] - The open state of the agreement modal.
 * @param {Function} [props.setIsOpen] - The function to set the agreement modal open state.
 * @param {React.ReactNode} [props.children] - The child components to render.
 * @returns {JSX.Element} The rendered Agreement component.
 */
export const Agreement = ({
  agreementId,
  setAgreementId,
  creatorId,
  assignmentId,
  isOpen,
  setIsOpen,
}) => {
  return (
    <section className="agreement">
      <AgreementProvider
        agreementId={agreementId}
        setAgreementId={setAgreementId}
        creatorId={creatorId}
        assignmentId={assignmentId}
      >
        <AgreementModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <AgreementByUIState />
        </AgreementModal>
      </AgreementProvider>
    </section>
  );
};
