import { showToast } from "components/Toasts/helpers/showToast";

import { actionCreator, privateHeaders } from "../../../actions";
import {
  GET_ASSIGNMENTS_ERROR,
  GET_CREATOR_BALANCES_ERROR,
  GET_FORECAST_URL,
  GET_MILESTONES_ERROR,
  READ_ASSIGNMENT_TOTALS_URL,
  READ_MANY_ASSIGNMENTS_URL,
  dashboardActionTypes,
} from "../constants";
import {
  processDueAssignments,
  processDueMilestones,
  processUpcomingAssignments,
  processUpcomingMilestones,
} from "../helpers/processDueAndUpcoming";

export const getCreatorBalances = () => {
  return async (dispatch) => {
    dispatch(actionCreator(dashboardActionTypes.getCreatorBalances.REQUEST));
    try {
      const url = new URL(READ_ASSIGNMENT_TOTALS_URL);
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const response = await fetch(request);
      if (!response || !response.ok || response.status !== 200) {
        throw new Error("Error getting Stripe balances");
      } else {
        const responseJson = await response.json();
        dispatch(
          actionCreator(
            dashboardActionTypes.getCreatorBalances.SUCCESS,
            responseJson.result,
          ),
        );
      }
    } catch (error) {
      const message = error?.message
        ? `${GET_CREATOR_BALANCES_ERROR}: ${error.message}`
        : GET_CREATOR_BALANCES_ERROR;
      showToast({ message, type: "error" });
      dispatch(
        actionCreator(dashboardActionTypes.getCreatorBalances.FAILURE, message),
      );
    }
  };
};

export const getMilestonesUpcoming = () => {
  return async (dispatch) => {
    dispatch(actionCreator(dashboardActionTypes.getMilestonesUpcoming.REQUEST));
    try {
      let url = new URL(READ_MANY_ASSIGNMENTS_URL);
      const params = url.searchParams;
      params.set("skip", "0");
      params.set("limit", "0");
      params.set("onlyUpcomingMilestonesAndAssignments", "true");
      params.set("calculate", "assignmentBalances");
      params.set("populate", "user_id campaign_id");
      url = new URL(url);
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const assignments = await fetch(request);
      if (!assignments || !assignments.ok || assignments.status !== 200) {
        throw new Error(GET_MILESTONES_ERROR);
      } else {
        let assignmentsJson = await assignments.json();
        assignmentsJson = processUpcomingMilestones(assignmentsJson?.results);
        dispatch(
          actionCreator(
            dashboardActionTypes.getMilestonesUpcoming.SUCCESS,
            assignmentsJson,
          ),
        );
      }
    } catch (error) {
      dispatch(
        actionCreator(
          dashboardActionTypes.getMilestonesUpcoming.FAILURE,
          error?.message || GET_MILESTONES_ERROR,
        ),
      );
    }
  };
};

export const getMilestonesDue = () => {
  return async (dispatch) => {
    dispatch(actionCreator(dashboardActionTypes.getMilestonesDue.REQUEST));
    try {
      let url = new URL(READ_MANY_ASSIGNMENTS_URL);
      const params = url.searchParams;
      params.set("skip", "0");
      params.set("limit", "0");
      params.set("hasMilestonePaymentsDue", "true");
      params.set("calculate", "assignmentBalances");
      params.set("populate", "user_id campaign_id");
      url = new URL(url);
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const assignments = await fetch(request);
      if (!assignments || !assignments.ok || assignments.status !== 200) {
        throw new Error(GET_MILESTONES_ERROR);
      } else {
        let assignmentsJson = await assignments.json();
        assignmentsJson = processDueMilestones(assignmentsJson?.results);
        dispatch(
          actionCreator(
            dashboardActionTypes.getMilestonesDue.SUCCESS,
            assignmentsJson,
          ),
        );
      }
    } catch (error) {
      dispatch(
        actionCreator(
          dashboardActionTypes.getMilestonesDue.FAILURE,
          error?.message || GET_MILESTONES_ERROR,
        ),
      );
    }
  };
};

export const getAssignmentsUpcoming = () => {
  return async (dispatch) => {
    dispatch(
      actionCreator(dashboardActionTypes.getAssignmentsUpcoming.REQUEST),
    );
    try {
      let url = new URL(READ_MANY_ASSIGNMENTS_URL);
      const params = url.searchParams;
      params.set("skip", "0");
      params.set("limit", "0");
      params.set("onlyUpcomingMilestonesAndAssignments", "true");
      params.set("calculate", "assignmentBalances");
      params.set("populate", "user_id campaign_id");
      url = new URL(url);
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const assignments = await fetch(request);
      if (!assignments || !assignments.ok || assignments.status !== 200) {
        throw new Error(GET_ASSIGNMENTS_ERROR);
      } else {
        let assignmentsJson = await assignments.json();
        assignmentsJson = processUpcomingAssignments(assignmentsJson?.results);
        dispatch(
          actionCreator(
            dashboardActionTypes.getAssignmentsUpcoming.SUCCESS,
            assignmentsJson,
          ),
        );
      }
    } catch (error) {
      dispatch(
        actionCreator(
          dashboardActionTypes.getAssignmentsUpcoming.FAILURE,
          error?.message || GET_ASSIGNMENTS_ERROR,
        ),
      );
    }
  };
};

export const getAssignmentsDue = () => {
  return async (dispatch) => {
    dispatch(actionCreator(dashboardActionTypes.getAssignmentsDue.REQUEST));
    try {
      let url = new URL(READ_MANY_ASSIGNMENTS_URL);
      const params = url.searchParams;
      params.set("skip", "0");
      params.set("limit", "0");
      params.set("hasMilestonePaymentsDue", "true");
      params.set("calculate", "assignmentBalances");
      params.set("populate", "user_id campaign_id");
      url = new URL(url);
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const assignments = await fetch(request);
      if (!assignments || !assignments.ok || assignments.status !== 200) {
        throw new Error(GET_ASSIGNMENTS_ERROR);
      } else {
        let assignmentsJson = await assignments.json();
        assignmentsJson = processDueAssignments(assignmentsJson?.results);
        dispatch(
          actionCreator(
            dashboardActionTypes.getAssignmentsDue.SUCCESS,
            assignmentsJson,
          ),
        );
        return Promise.resolve(assignmentsJson);
      }
    } catch (error) {
      dispatch(
        actionCreator(
          dashboardActionTypes.getAssignmentsDue.FAILURE,
          error?.message || GET_ASSIGNMENTS_ERROR,
        ),
      );
      return Promise.reject(error);
    }
  };
};

export const getForecastData = () => {
  return async (dispatch) => {
    dispatch(actionCreator(dashboardActionTypes.getForecastData.REQUEST));
    try {
      const url = new URL(GET_FORECAST_URL);
      const request = new Request(url, {
        method: "GET",
        credentials: "include",
        headers: privateHeaders(),
      });
      const response = await fetch(request);
      if (!response || !response.ok) {
        throw new Error("Error getting forecasted payments data");
      } else {
        const responseJson = await response.json();
        dispatch(
          actionCreator(
            dashboardActionTypes.getForecastData.SUCCESS,
            responseJson.result[0],
          ),
        );
      }
    } catch (error) {
      const message = error?.message
        ? `${GET_CREATOR_BALANCES_ERROR}: ${error.message}`
        : GET_CREATOR_BALANCES_ERROR;
      console.error({ message, error });
      // showToast({ message, type: "error" });
      dispatch(
        actionCreator(dashboardActionTypes.getForecastData.FAILURE, message),
      );
    }
  };
};
