import { useMemo } from "react";

import clsx from "clsx";

import { AgreementsGrid } from "features/agreements2/components/AgreementsGrid";

export const AssignmentAgreements = ({
  assignment_id,
  className,
  ...props
}) => {
  const classes = clsx("assignment-agreements", className);
  const filterParams = useMemo(() => {
    return {
      assignment_id: assignment_id,
    };
  }, [assignment_id]);
  return (
    <AgreementsGrid
      filterParams={filterParams}
      className={classes}
      gridId="assignment-agreements"
      excludeDataSources="assignment"
      assignmentId={assignment_id}
      create={true}
    />
  );
};
