import { useState } from "react";

import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { GlobeAltIcon, PlusIcon } from "@heroicons/react/24/outline";

import { AssignExistingCreatorModalButton } from "features/creators/components/AssignExistingCreatorModalButton";
import { InviteCreatorModalButton } from "features/creators/components/InviteCreatorModalButton";

// import AssignExistingCreatorsModalButton from "../AssignExistingCreatorsModalButton";

export const AssignCreator = ({ campaign_id, isNew = false, ...props }) => {
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [globalModalOpen, setGlobalModalOpen] = useState(false);

  const toggleAssignModal = () => setAssignModalOpen(!assignModalOpen);
  const toggleGlobalModal = () => setGlobalModalOpen(!globalModalOpen);

  return (
    <div className="assign-creator">
      <Button
        color="dark"
        // outline={false}
        // size={bold ? "" : "sm"}
        className="ms-3 pos-absolute d-flex align-items-center"
        onClick={() => setAssignModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        Assign Creator
      </Button>
      <Modal
        isOpen={assignModalOpen}
        size="md"
        toggle={toggleAssignModal}
        className="assign-creators-modal"
        backdrop="static"
        unmountOnClose={true}
      >
        <ModalHeader toggle={toggleAssignModal}>
          {isNew ? "Add Creator" : "Assign Creator to Campaign"}
        </ModalHeader>
        <ModalBody>
          {!isNew && (
            <AssignExistingCreatorModalButton
              campaign_id={campaign_id}
              btnText="Assign Existing"
              color="dark"
              outline
            />
          )}
          <InviteCreatorModalButton
            btnText="Email Invite"
            color="dark"
            campaign_id={campaign_id}
            outline
          />
          <Button
            color="dark"
            outline
            className="d-flex align-items-center"
            onClick={() => setGlobalModalOpen(true)}
          >
            <GlobeAltIcon height={20} width={20} className="me-2" />
            Global Invite
          </Button>
          <Modal isOpen={globalModalOpen} size="lg" toggle={toggleGlobalModal}>
            <ModalHeader toggle={toggleAssignModal}>
              Global Invite Link
            </ModalHeader>
            <ModalBody>
              <blockquote className="blockquote">
                <code>
                  <p className="small">
                    {`${import.meta.env.VITE_CREATORS_URL}/signup?token=${campaign_id}.RATE`}
                  </p>
                </code>
              </blockquote>
              <p>
                When using the invite code,{" "}
                <strong>replace the word &quot;RATE&quot;</strong> with the
                dollar value of the rate of the creator being assigned to the
                campaign.
              </p>
              <Alert color="warning">
                <ul>
                  <li>
                    Do not send this link to a creator who already has an
                    account.
                  </li>
                  <li>
                    Do not send this link to a creator without changing
                    &quot;RATE&quot; to a numerical value.
                  </li>
                  <li>
                    Do not delete the period between the ID of the campaign and
                    the RATE.
                  </li>
                </ul>
              </Alert>
            </ModalBody>
          </Modal>
        </ModalBody>
      </Modal>
    </div>
  );
};
