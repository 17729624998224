import DataGrid from "components/DataGrid";

import { topupsColDefs } from "./constants/columnDefs";
import { apiSlice } from "app/apiSlice";

const PastTopupsPane = ({ activeTabId, tabId, ...props }) => {
  const { data, isLoading } = apiSlice.useGetTopupsQuery();
  const pastTopups = data?.result?.allPastTopups ?? [];
  return (
    <DataGrid
      gridId="past-topups"
      borders
      idField="id"
      visible={activeTabId === tabId}
      rowData={pastTopups}
      columnDefs={topupsColDefs}
      autoSizeEnabled={true}
      loading={isLoading}
    />
  );
};

export default PastTopupsPane;
