import { createTransform } from "redux-persist";
import { apiSlice } from "app/apiSlice";
import { persistedEndpointNames } from "../constants/persistedEndpoints";

type ApiState = ReturnType<typeof apiSlice.reducer>;

export const apiTransform = createTransform(
  (inboundState: ApiState | undefined) => {
    if (!inboundState?.queries) {
      return inboundState;
    }

    const queries = {};

    for (const [key, value] of Object.entries(inboundState.queries)) {
      const endpointName = key.split("(")[0];
      if (
        value?.status === "fulfilled" &&
        value?.data &&
        persistedEndpointNames.has(endpointName)
      ) {
        queries[key] = value;
      }
    }

    // Return minimal state shape, RTK Query will reconstruct the rest
    return {
      ...inboundState,
      queries,
    };
  },
  (outboundState) => outboundState,
  { whitelist: [apiSlice.reducerPath] },
);
