import { createRequestActionTypes } from "../../../actions";

export const READ_MANY_BRANDS_URL = `${import.meta.env.VITE_API_URL}/api/v1/brands`;
export const CREATE_ONE_BRAND_URL = `${import.meta.env.VITE_API_URL}/api/v1/brand`;
export const GET_BRANDS_ERROR = "Error getting brands";
export const CREATE_BRAND_ERROR = "Error creating brand";
export const CREATE_BRAND_SUCCESS = "Brand created successfully";

/**
 * The action types for brands.
 * @type {Object}
 * @property {string} getBrands.request - The request action type for getting brands.
 * @property {string} getBrands.success - The success action type for getting brands.
 * @property {string} getBrands.failure - The failure action type for getting brands.
 * @property {string} createBrand.request - The request action type for creating a brand.
 * @property {string} createBrand.success - The success action type for creating a brand.
 * @property {string} createBrand.failure - The failure action type for creating a brand.
 */
export const brandsActionTypes = {
  getBrands: createRequestActionTypes("GET_BRANDS"),
  createBrand: createRequestActionTypes("CREATE_BRAND"),
};

/**
 * @deprecated // !deprecated - Use brandsColDefs in features/brands/constants instead.
 * The column definitions for brands grid.
 *
 * @type {Array}
 * @property {Object} headerName - The name of the column header.
 * @property {string} field - The name of the field in the data that the column is associated with.
 * @property {string} type - The type of data in the column.
 */
export const brandsColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Name",
    field: "name",
    type: "text",
    editing: { required: true },
  },
  // TODO: add column for assignment counts (campaigns, creators, brandUsers)
  {
    headerName: "Campaigns",
    field: "__campaignBalances.count",
    type: "number",
  },
  {
    headerName: "Funded",
    field: "__campaignBalances.fundedAmount",
    type: "currency",
  },
  {
    headerName: "Invoiced",
    field: "__campaignBalances.invoicedAmount",
    type: "currency",
  },
  {
    headerName: "Creator Budget",
    field: "__campaignBalances.creatorBudget",
    type: "currency",
  },
  {
    headerName: "SOW Amount",
    field: "__campaignBalances.sowAmount",
    type: "currency",
  },
  {
    headerName: "Allocated",
    field: "__campaignBalances.allocatedBalance",
    type: "currency",
  },
  { headerName: "Created", field: "created", type: "date", sort: "desc" },
];

// ! deprecated
export const brandsDataDefs = [
  {
    label: "Brand ID",
    fieldKey: "_id",
    type: "id",
    editing: false,
  },
  {
    label: "Brand/Company Name",
    fieldKey: "name",
    type: "string",
    // TODO: @segmentation review copy
    description:
      "The name of the company paying for campaigns (not the product's brand name).",
    editing: { required: true },
  },
  {
    label: "Short Code",
    fieldKey: "shortCode",
    type: "string",
    description:
      "A 3 character abbreviation for the brand. Must be unique. Used for constructing campaign short IDs.",
    editing: { required: true },
    // TODO: @segmentation require conditions, validation, custom component
  },
  {
    label: "Users",
    description:
      "The number of brand users able to log in to this brand's client portal.",
    fieldKey: "__brandUsers",
    type: "number",
    editing: false,
  },
  { label: "Created Date", fieldKey: "created", type: "date", editing: false },
  {
    label: "Campaign Metrics",
    role: "section",
    fields: [
      // TODO: add fields for other assignment counts (assignments, milestones)
      {
        label: "Campaigns",
        fieldKey: "__campaignBalances.count",
        type: "number",
        editing: false,
      },
      {
        label: "Invoiced",
        fieldKey: "__campaignBalances.invoicedAmount",
        type: "currency",
        editing: false,
      },
      {
        label: "Funded",
        fieldKey: "__campaignBalances.fundedAmount",
        type: "currency",
        editing: false,
      },
      {
        label: "Allocated",
        fieldKey: "__campaignBalances.allocatedBalance",
        type: "currency",
        editing: false,
      },
      {
        label: "Unallocated",
        fieldKey: "__campaignBalances.unallocatedBalance",
        type: "currency",
        editing: false,
      },
      {
        label: "Creator Budget",
        fieldKey: "__campaignBalances.creatorBudget",
        type: "currency",
        editing: false,
      },
      {
        label: "Paid to Creators",
        fieldKey: "__campaignBalances.amountPaidToCreators",
        type: "currency",
        editing: false,
      },
      {
        label: "SOW Amount",
        fieldKey: "__campaignBalances.sowAmount",
        type: "currency",
        editing: false,
      },
    ],
  },
];

export const brandUsersColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Name",
    field: "name",
    type: "text",
    editing: { required: true },
  },
  {
    headerName: "Email",
    field: "email",
    type: "text",
  },
  { headerName: "Disabled?", field: "disabled", type: "boolean" },
  {
    headerName: "Enable Notifications?",
    field: "enableNotifications",
    type: "boolean",
  },
];
export const brandUsersDataDefs = [
  {
    label: "Name",
    fieldKey: "name",
    type: "text",
    editing: { required: true },
  },
  {
    label: "Email",
    fieldKey: "email",
    type: "text",
    editing: {
      disableCondition: (values) => values?._id,
    },
  },
  {
    label: "Enable Notifications?",
    fieldKey: "enableNotifications",
    type: "switch",
    editing: true,
    defaultValue: true,
  },
  {
    label: "Disabled?",
    fieldKey: "disabled",
    type: "switch",
    editing: {
      hideCondition: (values) => !values?._id,
    },
  },
  {
    label: "Last Login",
    fieldKey: "lastLogin",
    type: "date",
    editing: false,
  },
  {
    label: "First Login",
    fieldKey: "firstLogin",
    type: "date",
    editing: false,
  },
  {
    label: "Created",
    fieldKey: "created",
    type: "date",
    editing: false,
  },
];
