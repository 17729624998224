import { CampaignAgreements } from "@/features/campaigns/components/CampaignAgreements";
import {
  CampaignAssignments,
  CampaignMilestones,
  CampaignTransfers,
  CampaignInvoices,
} from "features/campaigns/components";

export const campaignTabOptions = [
  {
    label: "Creator Assignments",
    id: "creators",
    Pane: CampaignAssignments,
    to: "../creators",
  },
  {
    label: "Milestones",
    id: "milestones",
    Pane: CampaignMilestones,
    to: "../milestones",
  },
  {
    label: "Transfers",
    id: "transfers",
    Pane: CampaignTransfers,
    to: "../transfers",
  },
  {
    label: "Invoices",
    id: "invoices",
    Pane: CampaignInvoices,
    to: "../invoices",
  },
  {
    label: "Agreements",
    id: "agreements",
    Pane: CampaignAgreements,
    to: "../agreements",
  },
];
