import { useCallback, useState } from "react";

import clsx from "clsx";
import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/solid";

import { apiSlice } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import { Agreement } from "features/agreements2/components/Agreement/Agreement";
import { agreementsColDefs } from "features/agreements2/constants/agreementsColDefs";

const emptyArray = [];

const CreateAgreementInstructions = () => {
  return (
    <p className="text-muted small mx-auto my-0" style={{ maxWidth: "24em" }}>
      To create a new agreement go to an Assignment or Creator page, then
      Agreements tab.
    </p>
  );
};

/**
 * Renders grid of creator Agreements with optional filters.
 *
 * @param {Object} props
 * @param {string} [props.className]
 * @param {string} [props.gridId]
 * @param {string} [props.creatorId]
 * @param {string} [props.assignmentId]
 * @param {React.ReactElement} [props.HeaderActionElem]
 * @param {string} [props.excludeDataSources] - Comma separated string of dataSources. Column definitions with matching data sources (DataSources) will be excluded from the grid definition.
 * @param {boolean} [props.create]
 * @param {Object} [props.filterParams]
 * @param {Object} [props.filterParams]
 * @param {number} [props.filterParams.skip=0]
 * @param {number} [props.filterParams.limit=0]
 * @param {string} [props.filterParams.sortKey]
 * @param {number} [props.filterParams.sortValue]
 * @param {boolean} [props.filterParams.creatorHasSigned]
 * @param {boolean} [props.filterParams.adminHasSigned]
 * @param {boolean} [props.filterParams.requiresAdminSignature]
 * @param {boolean} [props.filterParams.requiresCreatorSignature]
 * @param {boolean} [props.filterParams.sortByUserActionRequired]
 * @param {boolean} [props.filterParams.isRequired]
 * @param {boolean} [props.filterParams.adminHasSent]
 * @param {string} [props.filterParams.campaign_id]
 * @param {string} [props.filterParams.user_id]
 * @param {string} [props.filterParams.brand_id]
 * @param {string} [props.filterParams.milestone_id]
 * @param {string} [props.filterParams.deliverable_id]
 * @param {string} [props.filterParams.assignment_id]
 * @param {string} [props.filterParams.createdByAdmin_id]
 * @param {string} [props.filterParams.adminEmailAddress]
 * @param {string} [props.filterParams.status]
 * @param {string} [props.filterParams.agreementType]
 */
export const AgreementsGrid = ({
  filterParams = {},
  className,
  gridId = "",
  create = false,
  excludeDataSources = "",
  creatorId = null,
  assignmentId = null,
  ...restProps
}) => {
  const {
    data: agreements,
    isFetching,
    isLoading,
    refetch,
  } = apiSlice.useGetAgreementsQuery(filterParams);

  const [activeAgreement_id, setActiveAgreement_id] = useState(null);
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);

  const handleAgreementClick = useCallback(
    ({ _id }) => {
      setActiveAgreement_id(_id);
      setAgreementModalOpen(!!_id);
    },
    [setActiveAgreement_id, setAgreementModalOpen],
  );

  const handleCreateClick = useCallback(() => {
    setActiveAgreement_id(null);
    setAgreementModalOpen(true);
  }, []);

  const classes = clsx("agreements-grid", "h-100", className);
  const id = (gridId += ".agreements");

  const CreateAgreementButton = () => (
    <Button
      color="dark"
      className="d-flex align-items-center create-agreements-button"
      onClick={handleCreateClick}
    >
      <PlusIcon height={20} width={20} className="me-2" />
      New Agreement
    </Button>
  );

  return (
    <div className={classes}>
      <DataGrid
        appliedFilters={true}
        autoSizeIgnoreHeaders={false}
        excludeDataSources={excludeDataSources}
        borders={true}
        columnDefs={agreementsColDefs}
        gridId={id}
        idField="_id"
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
        quickFilter={true}
        rowData={agreements ?? emptyArray}
        actionText={"View"}
        actionClick={handleAgreementClick}
        HeaderActionElem={
          create ? <CreateAgreementButton /> : <CreateAgreementInstructions />
        }
        {...restProps}
      />
      <Agreement
        agreementId={activeAgreement_id}
        setAgreementId={setActiveAgreement_id}
        isOpen={agreementModalOpen}
        setIsOpen={setAgreementModalOpen}
        creatorId={creatorId}
        assignmentId={assignmentId}
      />
    </div>
  );
};
