import { connect } from "react-redux";
import { Col, Row } from "reactstrap";

import CampaignsBalanceCard from "modules/Dashboard/components/StripeBalanceCard/CampaignsBalanceCard";
import CreatorBalanceCard from "../CreatorBalanceCard";
import StripeBalanceCard from "../StripeBalanceCard";
import SegmentedBalanceCard from "../StripeBalanceCard/SegmentedBalanceCard";
import TopupsCard from "../TopupsCard";

const Balances = () => {
  return (
    <>
      <Col md={6}>
        <Row className="g-4">
          <Col xs={6}>
            <StripeBalanceCard />
          </Col>
          <Col xs={6}>
            <SegmentedBalanceCard />
          </Col>
          <Col xs={12}>
            <CampaignsBalanceCard />
          </Col>
          <Col xs={12}>
            <CreatorBalanceCard />
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        <TopupsCard />
      </Col>
    </>
  );
};

export default Balances;
