import clsx from "clsx";

import { NavLink } from "components/NavLink";

/**
 * Renders a link to a campaign based on the provided campaign ID.
 *
 * @param {Object} props - The component props.
 * @param {string} props._id - The ID of the campaign.
 * @param {Object} props.data
 * @returns {JSX.Element} The rendered CampaignLink component.
 */
export const CampaignLink = ({ _id, data }) => {
  let text;
  let muted;
  if (!_id) {
    text = "";
  } else if (!data?.campaign_id?.name && !data?.name) {
    text = "Can not display campaign data.";
  } else {
    text = data.campaign_id?.name || data.name;
  }
  const linkClassName = clsx("p-0", {
    "small text-muted": muted,
    "link-accent-dark": !muted,
  });
  return (
    <NavLink className={linkClassName} to={`/campaigns/${_id}`}>
      {text}
    </NavLink>
  );
};
