import { memo } from "react";

import WarningBoolCellRenderer from "@/components/DataGrid/customRenderers/WarningBoolCellRenderer";
import { AgreementStatusBadge } from "@/features/agreements2/components/AgreementStatusBadge";
import {
  AssignmentLinkCellRenderer,
  CampaignLinkCellRenderer,
} from "components/DataGrid/components";
import { BrandLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/BrandLinkCellRenderer";
import { CreatorLinkCellRenderer } from "components/DataGrid/components/LinkCellRenderers/CreatorLinkCellRenderer";
import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";

export const agreementsColDefs = [
  {
    headerName: "_id",
    field: "_id",
    type: "text",
    hide: true,
  },
  {
    headerName: "Title",
    field: "title",
    type: "text",
  },
  {
    // TODO: status chips
    headerName: "Status",
    field: "status",
    type: "text",
    cellRenderer: memo(AgreementStatusBadge),
  },
  {
    headerName: "Sent to Creator?",
    field: "adminHasSent",
    type: "boolean",
    // @ts-expect-error review worry about this later
    cellRenderer: memo(({ value, data }) => (
      <BoolCellRenderer
        value={value}
        type="icon"
        applyColor={!!data?.requiresCreatorSignature}
      />
    )),
  },
  {
    headerName: "Action Required (Admin)",
    field: "__isAdminActionRequired",
    type: "boolean",
    sort: "desc",
    cellRenderer: memo(WarningBoolCellRenderer),
    cellRendererParams: {
      type: "icon",
    },
    // ? pin right?
  },
  {
    headerName: "Creator ID",
    field: "user_id._id",
    dataSource: "creator",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Creator",
    field: "user_id._id",
    dataSource: "creator",
    cellRenderer: memo(CreatorLinkCellRenderer),
    type: "text",
    filterValueGetter: (params) => {
      // Makes the creator's name and ID searchable
      if (!params.data?.user_id?.name) {
        return "";
      }
      return `${params.data?.user_id?.name} ${params.data?.user_id?._id}`;
    },
    valueFormatter: (params) => {
      return params.data?.user_id?.name || "";
    },
  },
  {
    headerName: "Assignment ID",
    field: "assignment_id._id",
    dataSource: "assignment",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Assignment",
    field: "assignment_id._id",
    dataSource: "assignment",
    cellRenderer: memo(AssignmentLinkCellRenderer),
    type: "text",
    initialWidth: 200,
    filterValueGetter: (params) => {
      // Makes the assignment's creator name, campaign name, and assignment ID searchable
      if (!params.data?.user_id?.name || !params.data?.campaign_id?.name) {
        return "";
      }
      return `${params.data?.user_id?.name} ${params.data?.campaign_id?.name} ${params.data?.assignment_id}`;
    },
    valueFormatter: (params) => {
      return `${params.data?.user_id?.name || "unknown"} <> ${params.data?.campaign_id?.name || "unknown"}`;
    },
  },
  {
    headerName: "Required for Payment?",
    field: "isRequired",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Creator Signature Required?",
    field: "requiresCreatorSignature",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Creator Signed?",
    field: "creatorHasSigned",
    type: "boolean",
    // @ts-expect-error review worry about this later
    cellRenderer: memo(({ value, data }) => (
      <BoolCellRenderer
        value={value}
        type="icon"
        applyColor={!!data?.requiresCreatorSignature}
      />
    )),
  },
  {
    headerName: "Admin Signature Required?",
    field: "requiresAdminSignature",
    type: "boolean",
    // @ts-expect-error review worry about this later
    cellRenderer: memo(({ value, data }) => (
      <BoolCellRenderer
        value={value}
        type="icon"
        applyColor={!!data?.requiresAdminSignature}
      />
    )),
  },
  {
    headerName: "Admin Signed?",
    field: "adminHasSigned",
    type: "boolean",
    // @ts-expect-error review worry about this later
    cellRenderer: memo(({ value, data }) => (
      <BoolCellRenderer
        value={value}
        type="icon"
        applyColor={!!data?.requiresAdminSignature}
      />
    )),
  },
  {
    headerName: "Admin Signer",
    field: "adminEmailAddress",
    type: "text",
  },
  {
    headerName: "Campaign ID",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Campaign",
    field: "campaign_id._id",
    dataSource: "campaign",
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    filterValueGetter: (params) => {
      // Makes the campaign's name and ID searchable
      const campaignName
        = params.data?.campaign_id?.name || params.data?.campaignName;
      if (!campaignName) {
        return "";
      }
      return `${campaignName} ${params.data?.campaign_id?._id}`;
    },
    valueFormatter: (params) => {
      return params.data?.campaign_id?.name || "";
    },
  },
  {
    headerName: "Campaign Short ID",
    field: "campaign_id._id",
    dataSource: "campaign",
    // colId: "campaign.shortId", // ! if I leave this as just "campaign" it causes a crash after updating a milestone with `this.column` is null from AGGrid getColId. Probably because neither the field or colId properties are unique
    type: "text",
    cellRenderer: memo(CampaignLinkCellRenderer),
    cellRendererParams: {
      linkTextKey: "campaign_id.shortId",
    },
    filterValueGetter: (params) => {
      return params.data?.campaign_id?.shortId;
    },
    valueFormatter: (params) => {
      return params.data?.campaign_id?.shortId || "";
    },
  },
  {
    headerName: "Brand ID",
    field: "brand_id._id",
    dataSource: "brand._id",
    type: "text",
    hide: true,
    lockVisible: true,
  },
  {
    headerName: "Brand",
    field: "brand_id._id",
    dataSource: "brand._id",
    cellRenderer: memo(BrandLinkCellRenderer),
    filterValueGetter: (params) => {
      // Makes the brand's name and ID searchable
      if (!params.data?.brand_id?.name) {
        return "";
      }
      return `${params.data?.brand_id?.name} ${params.data?.brand_id?._id}`;
    },
    valueFormatter: (params) => {
      return params.data?.brand_id?.name || "";
    },
  },
  {
    headerName: "Created",
    field: "created",
    type: "date",
  },
  {
    headerName: "Updated",
    field: "updated",
    type: "date",
  },
];
