import clsx from "clsx";
import { LinkIcon } from "@heroicons/react/20/solid";

import { NavLink } from "components/NavLink";

/**
 * Renders a link to an assignment based on the provided assignment ID. Intended for use in details panels. For an equivalent component to be used in Grid cells, see LinkCellRenderers.
 *
 * @param {Object} props
 * @param {string} props._id - The assignment _id
 * @param {Object} props.data
 * @returns {JSX.Element} The rendered AssignmentLink component.
 */
export const AssignmentLink = ({ _id, data }) => {
  let content;
  let muted = true;
  if (!_id) {
    content = "";
  } else if (!data?.user_id?.name || !data?.campaign_id?.name) {
    content = "Can not display assignment data.";
  } else {
    const creatorName = data.user_id?.name;
    const campaignName = data.campaign_id?.name;
    content = (
      <span className="d-flex align-items-center">
        {creatorName}
        <LinkIcon height={"1em"} width={"1em"} className="mx-2" />
        {campaignName}
      </span>
    );
    muted = false;
  }

  const linkClassName = clsx("p-0", {
    "small text-muted": muted,
    "link-accent-dark": !muted,
  });

  console.log("link", _id);

  return (
    <NavLink className={linkClassName} to={`/assignments/${_id}`}>
      {content}
    </NavLink>
  );
};
