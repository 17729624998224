export const agreementsStatusDict = {
  Draft: {
    label: "Draft",
    value: "Draft",
    color: "",
  },
  Completed: {
    label: "Completed",
    value: "Completed",
    color: "success",
  },
  InProgress: {
    label: "In Progress",
    value: "InProgress",
    color: "info",
  },
  revoked: {
    label: "Revoked",
    value: "Revoked",
    color: "danger",
  },
  declined: {
    label: "Declined",
    value: "Declined",
    color: "danger",
  },
  expired: {
    label: "Expired",
    value: "Expired",
    color: "danger",
  },
};
