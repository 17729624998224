import { useCallback, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { Button, Form, Label, Row } from "reactstrap";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useGetBrandUserQuery, useUpdateBrandUserMutation } from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelFooter from "components/Panel/PanelFooter";
import PanelHeader from "components/Panel/PanelHeader";
import { showToast } from "components/Toasts/helpers/showToast";
import { getChangedValues } from "helpers/getChangedValues";

import { brandUsersDataDefs } from "../constants";

const BrandUserAssignment = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { brand_id, assignment_id } = useParams();
  const navigate = useNavigate();
  const [updateBrandUser] = useUpdateBrandUserMutation();
  const { data, isLoading } = useGetBrandUserQuery(assignment_id, {
    skip: !assignment_id,
  });
  const brandUser = data?.result;

  const initialValues = getInitialValues(brandUsersDataDefs, brandUser);

  const validate = useCallback(
    (_values) => {
      // TODO: validate brand user
      return {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- temp for placeholder unused initialValues
    [initialValues],
  );

  const closeBrandUser = () => {
    navigate(`/brands/${brand_id}/users`);
  };

  const submit = useCallback(
    async (values, actions) => {
      try {
        const changedValues = getChangedValues(values, initialValues);
        if (Object.keys(changedValues).length) {
          await updateBrandUser({
            _id: assignment_id,
            ...changedValues,
          }).unwrap();
          showToast({
            type: "success",
            message: "Brand user saved successfully.",
          });
        }
        setIsEditing(false);
      } catch (error) {
        showToast({
          type: "error",
          message: `Error updating brand user. ${error?.data?.message || error?.message}`,
        });
        console.error("Error updating brand user", error);
      }
      actions.setSubmitting(false);
    },
    [assignment_id, initialValues, updateBrandUser],
  );

  if (!brandUser) return null;

  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
    // TODO: reset form values
  };

  return (
    <div key={brandUser?._id} className="brand-user-assignment">
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validate={validate}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel overlay={"expanded"}>
              <PanelHeader closeAction={closeBrandUser}>
                <h2 className="fs-4 d-flex align-items-center">
                  <Label className="text-muted small">Brand User:</Label>
                  {brandUser?.name}
                  {!isEditing && brandUser?._id && (
                    <Button
                      color="secondary"
                      outline
                      size="sm"
                      className="d-flex align-items-center ms-3"
                      onClick={() => setIsEditing(true)}
                    >
                      <PencilIcon height={20} width={20} />
                    </Button>
                  )}
                </h2>
              </PanelHeader>
              <PanelBody padded={true} split="horizontal" scrollable={true}>
                <Row>
                  {brandUsersDataDefs?.map((def, i) => {
                    return (
                      <div key={i} className="labeled-data-container">
                        <LabelledData
                          definition={def}
                          isEditing={isEditing}
                          data={brandUser}
                        />
                      </div>
                    );
                  })}
                </Row>
                {isEditing && (
                  <PanelFooter>
                    <UnsavedChangesPrompt />
                    <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                      <Button
                        color="secondary"
                        outline
                        size="lg"
                        className="d-flex align-items-center"
                        onClick={() => disableEditing(dirty)}
                      >
                        <XMarkIcon height={20} width={20} className="me-2" />
                        Cancel
                      </Button>
                      <Button
                        color="dark"
                        className="d-flex align-items-center"
                        type="submit"
                        size="lg"
                      >
                        <CheckIcon height={20} width={20} className="me-2" />
                        Save
                      </Button>
                    </div>
                  </PanelFooter>
                )}
              </PanelBody>
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BrandUserAssignment;
