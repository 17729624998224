import { Suspense, useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { generateKeyFromAuth, encryptionKeyManager } from "services/encryption";
import { persistor } from "store/store";

import Loader from "components/Loader";
import NavbarHeader from "components/NavbarHeader";
import { getLoggedInUser } from "modules/Login/actions";

const PrivatePageLayout = ({
  isAuthenticated,
  loadingAuth,
  getLoggedInUser,
  userData,
  ...props
}) => {
  const navigate = useNavigate();
  const startedInitialCheck = useRef(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [hydrationAttempted, setHydrationAttempted] = useState(false);

  // Handle auth check
  useEffect(() => {
    const checkAuth = async() => {
      if (!startedInitialCheck.current) {
        startedInitialCheck.current = true;
        console.debug("Starting auth check");
        try {
          const authData = await getLoggedInUser();
          console.debug("Completed auth check", authData);
          if (authData) {
            // Generate and set encryption key from auth data
            console.debug("generating key from auth data");
            const key = await generateKeyFromAuth(authData);
            console.debug("setting key", key);
            encryptionKeyManager.setKey(key);
          }
        } catch (error) {
          console.error("Auth check failed:", error);
        } finally {
          setAuthChecked(true);
        }
      }
    };

    checkAuth();
  }, [getLoggedInUser]);

  // Handle hydration after auth
  useEffect(() => {
    if (authChecked && isAuthenticated && !hydrationAttempted) {
      const attemptHydration = async() => {
        console.debug("Attempting hydration");
        try {
          await persistor.persist();
          console.debug("Hydration successful");
        } catch (error) {
          console.error("Hydration failed:", error);
          // If hydration fails, purge and continue
          await persistor.purge();
        } finally {
          setHydrationAttempted(true);
        }
      };
      attemptHydration();
    } else if (authChecked && !isAuthenticated) {
      // Not authenticated, mark hydration as attempted to allow navigation
      setHydrationAttempted(true);
    }
  }, [authChecked, isAuthenticated, getLoggedInUser, hydrationAttempted]);

  // Handle navigation after auth check
  useEffect(() => {
    if (!authChecked || !hydrationAttempted) {
      return;
    }

    if (!isAuthenticated) {
      console.debug("Not authenticated, navigating to login");
      navigate("/login");
    }
  }, [isAuthenticated, authChecked, hydrationAttempted, navigate]);

  // Render app
  return isAuthenticated && authChecked && hydrationAttempted ? (
    <>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <NavbarHeader />
        <main>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </main>
      </PersistGate>
    </>
  ) : (
    <Loader solid="true" />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  loadingAuth: state.login.loading,
  userData: state.login.user,
});

const ConnectedPrivatePageLayout = connect(mapStateToProps, {
  getLoggedInUser,
})(PrivatePageLayout);

export { ConnectedPrivatePageLayout as PrivatePageLayout };
